import { useEffect, useRef } from "react";

function ToolTip(props) {
  const tooltip = useRef(null);

  useEffect(() => {
    window.$$(tooltip.current).tooltip();
  }, []);

  return (
    <>
      <span ref={tooltip} title={props.title}>
        {props.children}
      </span>
    </>
  );
}

export default ToolTip;

import { useState } from "react";
import { useController } from "react-hook-form";
import { removeFile, uploadFile } from "../../actions/auth";
import { BASE_URL, errorType, VIDEO_MAX_SIZE } from "../../constants";
import Loader from "../Common/loader";

function UploadVideoField({
  width = "100%",
  height,
  control,
  rules,
  name,
  defaultValue,
  uploadText,
  label,
  errorClass,
  acceptedTypes,
  acceptedTypeExtension,
  setValue,
}) {
  // usehooks
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });

  const [isLoading, setIsLoading] = useState(false);
  const [exntensionError, setExtensionError] = useState(null);
  const [sizeError, setSizeError] = useState('');

  // on image change
  const handleFileChange = async (event) => {
    const formData = new FormData();
    let file = event?.target?.files[0];
    let currentFileExtension = file?.type.split("/")[1];
    formData.append("files", event?.target?.files[0]);
    if (
      acceptedTypeExtension?.length > 0 &&
      !acceptedTypeExtension.includes(currentFileExtension)
    ) {
      field.onChange("");
      setExtensionError(true);
      return;
    }

    const fileSizeByte = Math.floor(file.size / 1024) / 1000;

    if (fileSizeByte > VIDEO_MAX_SIZE) {
      field.onChange("");
      setExtensionError(false);
      setSizeError(true);
      return;
    }

    setIsLoading(true);
    setExtensionError(false);
    setSizeError(false);

    try {
      const resp = await uploadFile(formData);
      field.onChange(
        resp?.data?.responseData?.length > 0 && resp?.data?.responseData[0]
      );
      setIsLoading(false);
      event.target.value = '';
    } catch ({ request, response }) {
      setIsLoading(false);
      if (response) {
        // toast.error(response?.data?.message);
      } else if (request) {
        // toast.error("You do not have internet connection");
      }
    }
  };

  const onRemoveImage = async () => {
    setIsLoading(true);
    try {
      await removeFile({ ids: field?.value?.id });
      setValue(`${name}`, "");
      setIsLoading(false);
    } catch ({ request, response }) {
      setIsLoading(false);
      if (response) {
        // toast.error(response?.data?.message);
      } else if (request) {
        // toast.error("You do not have internet connection");
      }
    }
  };

  return (
    <div
      className={`form-in form-group w-100 ${error ? "required-field" : ""}`}
    >
      <label className="label">{label}</label>
      <div className="f-in w-100">
        <div className="image-upload">
          <span className="img-upload video">
            <img src="/assets/images/video.svg" />
          </span>
          <span className="img-text">{uploadText} {" "} (Max size{" "}
          {VIDEO_MAX_SIZE}MB) </span>
          {isLoading && <Loader />}
          <input
            className="VideoInput_input form-control"
            type="file"
            onChange={handleFileChange}
            accept={acceptedTypes}
          />
        </div>
      </div>

      {field.value && (
        <>
          <div className="d-flex uploaded-main-wrpr">
            <div className="uploaded-video-wrpr">
              <video
                className="VideoInput_video"
                width={width}
                height={height}
                controls
                src={`${BASE_URL}${field?.value?.path}`}
              />
            </div>
            <a
              href="javascript:;"
              className="link fw600 remove-btn"
              onClick={() => onRemoveImage()}
            >
              Remove
            </a>
          </div>
        </>
      )}

      {exntensionError ? (
        <p className="error">
          Invalid file extension. Allowed file extensions are{" "}
          {acceptedTypes?.map(
            (_type, index) =>
              `${_type} ${
                acceptedTypes?.length - 1 == index
                  ? ""
                  : acceptedTypes?.length - 2 == index
                  ? "and "
                  : ", "
              }`
          )}{" "}
        </p>
      ) : null}


{sizeError ? (
        <p className="error">
          Allowed file size is{" "}
          {VIDEO_MAX_SIZE}MB
        </p>
      ) : null}

      {errorType?.map((type) => {
        if (error?.type === type && error?.message !== "") {
          return (
            <span key={type} className={errorClass || "error-msg"}>
              {error?.message}
            </span>
          );
        }
      })}
    </div>
  );
}

export default UploadVideoField;

import axios from "axios";

export const USERS = "USERS";
export const DELETE_USER = "DELETE_USER";

export function login(payload){
    return axios({
        method: 'POST',
        data: payload,
        url: 'admin/login'
    })
}

export function forgotPassword(payload){
    return axios({
        method: 'POST',
        data: payload,
        url: 'admin/forgetPassword'
    })
}

export function resetPassword(payload){
    return axios({
        method: 'POST',
        data: payload,
        url: 'admin/resetPassword'
    })
}

export function verifyResetToken(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: 'admin/verifyToken'
    })
}

export function uploadFile(props) {
    return axios({
      method: 'POST',
      data: props,
      url: `/attachment/upload`
    })
  };

  export function removeFile(props) {
    return axios({
      method: 'DELETE',
      params: props,
      url: `attachment/delete`
    })
  };

  export function getCurrency(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: 'currency'
    })
}
import axios from "axios"

export function getTimeSlots(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/time-slot'
    })
}

export function getTimeSlotById(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/time-slot/id'
    })
}

export function postTimeSlot(payload){
    return axios({
        method: 'POST',
        data: payload,
        url: '/time-slot'
    })
}

export function UpdateTimeSlot(payload){
    return axios({
        method: 'PATCH',
        data: payload,
        url: '/time-slot'
    })
}

export function deleteTimeslot(payload){
    return axios({
        method: 'DELETE',
        params: payload,
        url: '/time-slot'
    })
}
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from "chart.js";
import moment from "moment";
import { Line } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

function LineGraphComponent({dashboardData, currentDayType}) {

  const options = {
    chart: {
      type: 'areaspline'
    },
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      // y: {
      //   grid: {
      //     display: false
      //   }
      // }
    },
    plugins: {
      legend: {
        position: "top",
        display: false
      },
    //   title: {
    //     position: "top",
    //     display: true,
    //     text: "Patient Visit",
    //   },
    },
  };

  const datesArray = currentDayType == 'LAST_YEAR' ? dashboardData?.graphData?.map(_date =>_date?.date) : dashboardData?.graphData?.map(_date => moment(_date?.Date).format('ll'));
  const dataSet = dashboardData?.graphData?.map(_date => _date?.count);
  const data = {
    labels: datesArray,
    datasets: [
      {
        label: "",
        data: dataSet,
        fill: true,
        backgroundColor: "#FFF6F1",
        borderColor: "#F07522",
      }
      // {
      //   label: "",
      //   data: [dashboardData?.totalAppointments],
      //   fill: false,
      //   backgroundColor: "rgba(16,138,97,0.2)",
      //   borderColor: "rgba(16,138,97,1)",
       
      // },
      // {
      //   label: "Shared Record",
      //   data: [0],
      //   fill: true,
      //   borderColor: "#742774",
      //   backgroundColor:"#F4EEFF",
      // },
      // {
      //   label: "Total no. of patients",
      //   data: [0],
      //   fill: false,
      //   backgroundColor: "#FFEBF8",
      //   borderColor:"#E817A0",
      // },
    ],
  };

  return (
    <>
      <Line options={options} data={data} height={'100%'} />
    </>
  );
}

export default LineGraphComponent;

import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useEffect, useState } from "react";
// import ToolTip from "../../../components/Common/ToolTip";
import ConfirmAlert from "../../../components/Common/Modals/ConfirmAlert";
import { BASE_URL } from "../../../constants";
import moment from "moment";
import { deleteBlog, editBlogs } from "../../../actions/cms";
import { toShortString } from "../../../utilities";
import CommonEmailPage from "../../../components/Common/CommonEmailPage";

function TableData({ srno, index, data, reload }) {
  //   local variables
  const [status, setStatus] = useState(data?.status);
  const [confirmShow, setConfirmShow] = useState(false);
  const [deleteBtnClicked, setDeleteBtnClicked] = useState(false);
  const [showEmail, setShowEmail] = useState(false);

  const updateBlogStatus = async (status) => {
    try {
      await editBlogs({ status, id: data?.id });
      if (reload) reload();
      toast.success("Status Updated Successfully");
    } catch (error) {
      toast.error(`${error?.response?.data?.message}`);
    }
  };

  const deleteRecord = async () => {
    try {
      await deleteBlog({ id: data?.id });
      toast.success("Blog has been deleted successfully");
      if (reload) reload();
    } catch (error) {
      toast.error(`${error?.response?.data?.message}`);
    }
  };

  function myFunction(e) {
    e.stopPropagation();
    document.querySelectorAll('.more-links').forEach(elem => elem.classList.remove('active'));
    e.target.nextSibling.classList.toggle("active");
  }

  const removeMenuDropDown = (e) => {
    if(!e.target?.classList?.contains('act-button')){
      document.querySelectorAll('.more-links').forEach(elem => elem.classList.remove('active'));
      return;
    }
  }

  useEffect(() => {
    document.addEventListener('click', removeMenuDropDown);

    return () => {
      document.removeEventListener('click', removeMenuDropDown);
    }
  }, []);

  //   use hooks
  useEffect(() => {
    setStatus(data?.status);
  }, [data.status]);

  return (
    <>
      <tr className="modal-tr">
        <td>{parseInt(srno + parseInt(index + 1))}</td>
        <td>
          <div class="table-img-wrpr">
          <img
            className="img-fluid table-img"
            src={
              data?.featureImage?.path
                ? `${BASE_URL}${data?.featureImage?.path}`
                : ""
            }
          ></img>
          </div>
        
        </td>
        <td className="manage-content">{toShortString(data?.defaultContent?.[0]?.title, 30)}</td>
        <td> {moment(data?.createdAt).format("MM/DD/Y h:mm A")}</td>
        <td>{data?.views ?? 0}</td>
        <td>
          {data?.Viewers?.map(
            (_viewer, index) =>
              ` ${
                _viewer?.roleId === 1
                  ? "Admin"
                  : _viewer?.roleId === 2
                  ? "Vet"
                  : "User"
              }${index < data?.Viewers?.length - 1 ? "," : ""}`
          )}
        </td>
        <td>
          {data?.Group?.defaultContent?.map(
            (_group, index) =>
              `${_group?.groupName}${
                index < data?.Group?.defaultContent?.length - 1 ? "," : ""
              }`
          )}
        </td>

        {/* <td>
          {data?.Group?.defaultContent?.map(
            (_group, index) =>
              `${_group?.groupName} ${
                index < data?.Group?.defaultContent?.length - 1 ? "," : ""
              }`
          )}
        </td> */}
        <td>
          {data?.status === 1
            ? "Pending"
            : data?.status === 2
            ? "Rejected"
            : data?.status === 3
            ? "Published"
            : data?.status === 4
            ? "Unpublished"
            : data?.status === 0 ?
          'Draft':'Revised'}
        </td>
        <td class="td-action">
          <div class="action-section table-btn-block">
            <div className="table-btn-block">
              <div className="table-btn-block">
                <Link
                  className="btn edit-btn"
                  to={`/dashboard/blogs/${data?.id}/edit`}
                >
                  Edit
                </Link>

                <button
                  className="btn delete-btn"
                  onClick={() => {
                    setDeleteBtnClicked(true);
                    setConfirmShow(true);
                  }}
                >
                  Delete
                </button>
              </div>
            </div>

            <a
              href="javascript:void(0);"
              class="act-button dotes-btn"
              title="Action"
              onClick={myFunction}
            >
            </a>
            <div class='more-links'>
              <ul class="more-menus">
                <li class="menu-links">
                  <a
                    class="sb-clnt"
                    href="javascript:void(0)"
                    onClick={() => {
                      setShowEmail(true);
                    }}
                  >
                    Send Email
                  </a>
                </li>
                <li class="menu-links">
                  <Link
                    class="sb-clnt"
                    to={`/dashboard/blogs/${data?.id}/detail`}
                  >
                    View
                  </Link>
                </li>
              
                {(data?.status == 1 ||
                data?.status == 0 ||
                  data?.status == 4 ||
                  data?.status == 3) && (
                  <li class="menu-links">
                    <a
                      href="javascript:;"
                      class="sb-clnt"
                      onClick={() => {
                        updateBlogStatus(data?.status == 3 ? 4 : 3);
                      }}
                    >
                      {data?.status == 3 ? "Unpublish" : "Publish"}
                    </a>
                  </li>
                )}
                {data?.status == 1 && (
                  <li class="menu-links">
                    <a
                      href="javascript:;"
                      class="sb-clnt"
                      onClick={() => {
                        updateBlogStatus(2);
                      }}
                    >
                      Reject
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </td>
      </tr>

      {showEmail ? (
        <CommonEmailPage
          show={showEmail}
          onHide={() => {
            setShowEmail(false);
          }}
          data={data}
          email={data?.User?.data?.email}
          title={data?.defaultContent?.[0]?.title}
        />
      ) : null}


      {confirmShow ? (
        <ConfirmAlert
          show={confirmShow}
          onHide={() => {
            setConfirmShow(false);
          }}
          title={
            deleteBtnClicked
              ? "Are you sure you want to delete this Blog?"
              : status === 0
              ? "Are you sure to activate Blog?"
              : "Are you sure to deactivate Blog?"
          }
          confirmButtonName={"Yes"}
          cancelButtonName={"No"}
          onButtonClick={() => {
            deleteBtnClicked ? deleteRecord() : updateBlogStatus();
          }}
        />
      ) : null}
    </>
  );
}

export default TableData;

import { DELETE_USER, USERS } from "../actions/auth";

const initialState = {
  userListing: null,
  userDetail: null,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case USERS:
      return {
        ...state,
        userListing: action.payload,
      };
    case DELETE_USER:
      const data = { ...state.userListing };
      let index = data?.data?.findIndex((item) => item.id === action.id);
      data?.data?.splice(index, 1);
      return {
        ...state,
        userListing: data,
      };
    default:
  }
  return state;
};
export default user;

import { useCallback, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import moment from "moment";
import { useLocation } from "react-router-dom";
import {PET_HEALTH_STATUS} from "../../constants";
import { getUserPetDetail } from "../../actions/Users";

function Detail() {
  // local variables
  const [detail, setDetail] = useState(null);
  const [petDetail, setPetDetail] = useState(null);
  // use hooks
  const location = useLocation();

  const fetchData = useCallback( async (userId) => {
   try {
      const resp = await getUserPetDetail({ownerId: userId});
      setPetDetail(resp?.data?.responseData);   
      
   } catch ({ response, request }) {
     if (response) {
       toast.error(response?.data?.message);
     } else if (request) {
       toast.error("You do not have internet connection");
     }
   }
  },[]);



  useEffect(() => {
    if (location.state) {
      setDetail(location?.state);
      fetchData(location?.state?.id)
    }
  }, [location]);

  console.log('dsassss', petDetail);

  return (
    <>
      <div className="container ani-ui-block shop-manager">
        <div className="row menu-top-block">
          <div className="col-sm-12 tl-block align-self-center">
            <h4 className="heading">Detail</h4>
          </div>
        </div>
        <div className="row menu-top-block">
          <div className="col-lg-12 col-md-12">
            <div className="add-left-block">
              <div className="fields-ui-block">
                <div className="basic-details form-block">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="info-ui">
                        <label>Name:</label>
                        <p>{detail?.UserProfile?.name}</p>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="info-ui">
                        <label>Email Id:</label>
                        <p>{detail?.email}</p>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="info-ui">
                        <label>Gender</label>
                        <p>{detail?.UserProfile?.gender}</p>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="info-ui">
                        <label>Phone Number:</label>

                        <p>
                          {detail?.countryCode &&
                            (detail?.countryCode?.includes("+")
                              ? detail?.countryCode
                              : `+${detail?.countryCode}`)}
                          &nbsp;
                          {detail?.mobile
                            ? detail?.mobile
                            : detail?.phoneNumber
                            ? detail?.phoneNumber
                            : "N.A"}
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="info-ui">
                        <label>Country</label>
                        <p>{detail?.UserProfile?.country}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="container ani-ui-block shop-manager">
        <div className="row menu-top-block">
          <div className="col-sm-12 tl-block align-self-center">
            <h4 className="heading">Pets ({petDetail?.pets.length})</h4>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="result-listing">
              <div className="table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th>Sr.no.</th>
                      <th className="manage-content">Name</th>
                      <th className="manage-content">Breed</th>
                      <th className="manage-content">Species</th>
                      <th className="manage-content">Status</th>
                      <th className="manage-content">License Number</th>
                      <th className="manage-content">Microchip Number</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      petDetail?.pets.length > 0 && petDetail?.pets.map((obj, index) => (
                        <tr key={index}>
                          <td>{index+1}</td>
                          <td>{obj?.name ?? null}</td>
                          <td>{obj?.Bred?.name ?? null}</td>
                          <td>{obj?.Specie?.name ?? null}</td>
                           <td>{PET_HEALTH_STATUS[obj?.status].label}</td>
                          <td>{obj?.licenseNumber ?? null}</td>
                          <td>{obj?.microchipNumber ?? null}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>




    </>
  );
}

export default Detail;

import { Modal } from "react-bootstrap";
import { BASE_URL } from "../../constants";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import InputTextAreaField from "../../components/FormFields/InputTextAreaField";
import Inputfield from "../../components/FormFields/InputField";
import { useEffect, useState } from "react";
import { sendEmail } from "../../actions/Users";

function CommonEmailPage({ show, onHide, data, title, email, showSubject }) {
  // use hooks
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm();

  let requiredEmail = []; 
  requiredEmail.push(email);

  // on form submissions
  const onSubmit = async (formData) => {
    console.log(formData, "formData");
    let records = {"subject": formData.subject ? formData.subject : title, "recipients": requiredEmail, "body": formData?.description};
    console.log('==a===', records);
    try {
      await sendEmail(records);
      toast.success("Message has been sent successfully.");
      onHide();
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  };




  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        className="modal fade"
        dialogClassName="modal-dialog modal-lg w700"
      >
        <div className="modal-header">
          <div className="title-btn-wrpr">
            <h2 className="modal-title mr20">{title}</h2>
          </div>
          <div className="table-btns modal-btns">
            <button type="button" className="close" onClick={onHide}>
              <img src="/assets/images/close-icon-dark.svg" />
            </button>
          </div>
        </div>

        <div className="modal-body">
          <div className="form-block vendor-details-modal">
            
              
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">



                  <div className="col-lg-12 col-md-12">
                    <div className="add-left-block">
                      <div className="fields-ui-block1">
                        <div className="basic-details">
                          
                          <div className="form-block">
                            
                            <div className="row">
                              {
                                showSubject
                                ?
                                <div className="col-lg-12 col-md-12">
                                  <Inputfield
                                    control={control}
                                    label="Subject"
                                    name="subject"
                                    normalize={(e) => e.replace(/^\s+/g, "")}
                                    type="text"
                                    fieldClass="form-control"
                                    rules={{
                                      required: {
                                        value: true,
                                        message: "Subject is required",
                                      },
                                      maxLength: {
                                          value: 100,
                                          message:
                                            "Maximum 100 characters are allowed!",
                                        }
                                    }}
                                  />
                                </div>
                                :
                                null
                              }
                              <div className="col-lg-12">
                                <InputTextAreaField
                                  control={control}
                                  name="description"
                                  label={"Description"}
                                  placeholder="Description"
                                  type="text"
                                  rules={{
                                    required: {
                                      value: true,
                                      message: "Description is required",
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row save-button-block">
                        <div className="col-sm-12 align-self-center">
                          <div className="btn-wrpr">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className={`btn-full orange-btn ${
                                isSubmitting && "btn-loader"
                              }`}
                            >
                              Send Message
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              

              

              
            
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CommonEmailPage;

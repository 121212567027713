import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Pagination from "react-js-pagination";

import { limit } from "../../constants";
import FilterBreed from "./FilterPlan";
import TableData from "./TableData";
import { GetPlans } from "../../actions/plan";

function Plan() {
  // use hooks
  const { search } = useLocation();

  // local variables
  const [list, setList] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [filters, setFilters] = useState(queryString.parse(search));

  const fetchData = useCallback( async (page, filters) => {
    try {
      const resp = await GetPlans({ ...filters, pageNumber: page});
      setList(resp?.data?.responseData);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  },[]);

  const handlePageChange = (page) => {
    setActivePage(page);
  };

  const getFilterFields = (filters) => {
    setActivePage(1);
    setFilters(filters);
  };

  useEffect(() => {
    fetchData(activePage, filters);
  }, [fetchData, activePage, filters]);

  return (
    <>
      <div className="right-ui-block">
        <div className="scrollspy-example">
          <div className="rm-content">
            <div className="row menu-top-block">
              <div className="col-sm-5 tl-block align-self-center">
                <h4 className="heading">
                  Plans ({list?.totalRecords ? list.totalRecords : 0})
                </h4>
              </div>

              <div className="col-sm-7 tr-block text-right align-self-center">
                <Link className="btn orange-btn" to={`/dashboard/plans/add`}>
                  Add
                </Link>
              </div>
            </div>
            <FilterBreed getFilterFields={getFilterFields} />
            <div className="row">
              <div className="col-sm-12">
                <div className="result-listing">
                  <div className="table-responsive">
                    <table>
                      <thead>
                        <tr>
                          <th>Sr.no.</th>
                          <th className="manage-content">Name</th>
                          <th className="manage-content">Description</th>
                          <th className="manage-content">Price</th>
                          <th className="manage-content">Active Subscriber</th>
                          <th className="manage-content">Total Subscriber</th>
                          <th className="manage-content">Status</th>
                          <th className="manage-content text-center">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {list === null ? (
                          <tr>
                            <td colSpan={10} align="center">
                              <div className="table-loader" />
                            </td>
                          </tr>
                        ) : list?.plans?.length > 0 ? (
                          list?.plans?.map?.((obj, index) => (
                            <TableData
                              key={obj.id}
                              data={obj}
                              index={index}
                              srno={(activePage - 1) * limit}
                              reload={() => fetchData(activePage, filters)}
                            />
                          ))
                        ) : (
                          <tr>
                            <td
                              className="no-record"
                              colSpan={10}
                              align="center"
                            >
                              No record found.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {list?.plans?.length > 0 && (
                    <div className="pagination">
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={
                            list?.perPage ? list?.perPage : limit
                        }
                        totalItemsCount={
                            list?.totalRecords ? list?.totalRecords : 0
                        }
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Plan;

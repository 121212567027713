import { Route, Routes } from "react-router-dom";

import Header from "../../components/Header/Header";
import LayoutSideNavigation from "./LayoutSideNavigation";
import Dashboard from "../Dashboard/Dashboard";
import BroadCastAndNotifications from "../BroadcastAndNotifications/BroadCastAndNotifications";
import GeneralSettings from "../GeneralSettings/GeneralSettings";
import Ticket from "../TicketManagement/Ticket";
import PaymentHistory from "../PaymentHistory/PaymentHistory";
import Vets from "../Users/Vets/Vets";
import Customers from "../Users/Customers/Customer";
import Animal from "../AnimalManagement/Animals/Animal";
import Roles from "../AclManegement/Roles/Roles";
import Staff from "../AclManegement/Staff/Staff";
import SpicesAndBreedsManagement from "../AnimalManagement/SpicesAndBreedManagement/SpicesAndBreadsManagement";
import AddCustomer from "../Users/Customers/AddCutomer";
import EditCustomer from "../Users/Customers/EditCustomer";
import AddVet from "../Users/Vets/AddVet";
import EditVet from "../Users/Vets/EditVet";
import EditAnimal from "../AnimalManagement/Animals/EditAnimal";
import AddAnimal from "../AnimalManagement/Animals/AddAnimal";
import AnimalDetail from "../AnimalManagement/Animals/AnimalDetail";
import AddBroadCast from "../BroadcastAndNotifications/AddBroadCast";
import EditBroadCast from "../BroadcastAndNotifications/EditBroadCast";
import Detail from "../DetailPage/Detail";
import VetsDetail from "../Users/Vets/Detail";
import AddRole from "../AclManegement/Roles/AddRole";
import EditRole from "../AclManegement/Roles/EditRole";
import Blogs from "../CMS/Blogs/Blogs";
import EditBlog from "../CMS/Blogs/EditBlog";
import AddBlog from "../CMS/Blogs/AddBlog";
import CategoryAndSubCategory from "../CMS/CategoryAndSubCategory";
import BlogDetail from "../CMS/Blogs/BlogDetail";
import Reports from "../Reports/Reports";
import AddStaff from "../AclManegement/Staff/AddStaff";
import EditStaff from "../AclManegement/Staff/EditStaff";
import TimeSlot from "../TimeSlotManagement/TimeSlot";
import EditTimeSlot from "../TimeSlotManagement/EditTimeSlot";
import AddTimeSlot from "../TimeSlotManagement/AddTimeSlot";
import CaregiverRole from "../CaregiverManagement/Caregiver";
import EditCaregiverRole from "../CaregiverManagement/EditCaregiver";
import AddCaregiverRole from "../CaregiverManagement/AddCaregiver";
import Ratings from "../Rattings/Ratings";
import Plan from "../Plans/Plan";
import EditPlan from "../Plans/EditPlan";
import AddPlan from "../Plans/AddPlan";
import VetsStaff from "../Users/Vets/Staff";
import VetsAddStaff from "../Users/Vets/AddStaff";
import Transactions from "../Transaction/Transactions";
import Diagnosis from "../AnimalManagement/Medication/Diagnosis";
import DiagnosisTest from "../AnimalManagement/Medication/DiagnosisTest";
import VisitReason from "../AnimalManagement/Medication/VisitReason";
import PresentingComplain from "../AnimalManagement/Medication/PresentingComplain";
import EditDiagnosis from "../AnimalManagement/Medication/EditDiagnosis";
import EditDiagnosisTest from "../AnimalManagement/Medication/EditDiagnosisTest";
import EditVisitReason from "../AnimalManagement/Medication/EditVisitReason";
import EditPresentingComplain from "../AnimalManagement/Medication/EditPresentingComplain";
import ManageMedication from "../AnimalManagement/Medication/ManageMedication";
import AddMedication from "../AnimalManagement/Medication/AddMedication";
import EditMedication from "../AnimalManagement/Medication/EditMedication";
import MedicationCsv from "../AnimalManagement/Medication/MedicationCsv";
import AddFeature from "../Plans/AddFeature";
import EditFeature from "../Plans/EditFeature";

function Layout() {
  return (
    <>
      <div className="wrapper-block">
        <Header />
        <div className="orders-ui-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-4 leftmenu">
                <LayoutSideNavigation />
              </div>
              <div className="col-lg-9 col-md-12 admin-right-ui">
                <Routes>
                  <Route index element={<Dashboard />} />

                  {/* customer routes */}
                  <Route path="customers" element={<Customers />} />
                  <Route path="customers/add" element={<AddCustomer />} />
                  <Route path="customers/:id/edit" element={<EditCustomer />} />
                  <Route path="customers/detail" element={<Detail />} />

                  {/* vet routes */}
                  <Route path="vets" element={<Vets />} />
                  <Route path="vets/add" element={<AddVet />} />
                  <Route path="vets/:id/edit" element={<EditVet />} />
                  <Route path="vets/:id/detail" element={<VetsDetail />} />
                  <Route path="vets/:id/:clinicId/staff" element={<VetsStaff />} />
                  <Route path="vets/:id/:clinicId/staff/add" element={<VetsAddStaff />} />

                  {/* cms routes */}
                  <Route
                    path="category/*"
                    element={<CategoryAndSubCategory />}
                  />

                  <Route path="blogs/:status" element={<Blogs />} />
                  <Route path="blogs/add" element={<AddBlog />} />
                  <Route path="blogs/:id/edit" element={<EditBlog />} />
                  <Route path="blogs/:id/detail" element={<BlogDetail />} />

                  {/* species and breed routes */}
                  <Route
                    path="species/*"
                    element={<SpicesAndBreedsManagement />}
                  />

                  {/* animal routes */}
                  <Route path="animals" element={<Animal />} />
                  <Route path="animals/add" element={<AddAnimal />} />
                  <Route path="animals/:id/edit" element={<EditAnimal />} />
                  <Route path="animals/:id/detail" element={<AnimalDetail />} />

                  {/* Notification routes */}
                  <Route
                    path="notifications"
                    element={<BroadCastAndNotifications />}
                  />
                  <Route path="notifications/add" element={<AddBroadCast />} />
                  <Route
                    path="notifications/:id/edit"
                    element={<EditBroadCast />}
                  />

                  {/* general setting routes */}
                  <Route
                    path="general-settings"
                    element={<GeneralSettings />}
                  />

                  {/* ticket routes */}
                  <Route path="tickets" element={<Ticket />} />

                  {/* payment routes */}
                  <Route path="payments" element={<PaymentHistory />} />

                  <Route path="reports/:code/*" element={<Reports />} />

                  <Route path="ratings/*" element={<Ratings />} />

                   {/* plan routes */}
                  <Route path="plans" element={<Plan />} />
                  <Route path="plans/add" element={<AddPlan />} />
                  <Route path="plans/:id/edit" element={<EditPlan />} />
                  <Route path="features/add" element={<AddFeature />} />
                  <Route path="features/:id/edit" element={<EditFeature />} />

                  {/* Acl management routes */}
                  <Route path="roles" element={<Roles />} />
                  <Route path="roles/add" element={<AddRole />} />
                  <Route path="roles/:id/edit" element={<EditRole />} />
                  <Route path="staff" element={<Staff />} />
                  <Route path="staff/add" element={<AddStaff />} />
                  <Route path="staff/:id/edit" element={<EditStaff />} />

                  <Route path="time-slot" element={<TimeSlot />} />
                  <Route path="time-slot/add" element={<AddTimeSlot />} />
                  <Route path="time-slot/:id/edit" element={<EditTimeSlot />} />

                  <Route path="caregiver-role" element={<CaregiverRole />} />
                  <Route path="caregiver-role/add" element={<AddCaregiverRole />} />
                  <Route path="caregiver-role/:id/edit" element={<EditCaregiverRole />} />

                   <Route path="transactions" element={<Transactions />} />
                   <Route path="diagnosis" element={<Diagnosis />} />
                   <Route path="diagnosis/:id/edit" element={<EditDiagnosis />} />
                   <Route path="diagnosis-test" element={<DiagnosisTest />} />
                   <Route path="diagnosis-test/:id/edit" element={<EditDiagnosisTest />} />
                   <Route path="visit-reason" element={<VisitReason />} />
                   <Route path="visit-reason/:id/edit" element={<EditVisitReason />} />
                   <Route path="presenting-complain" element={<PresentingComplain />} />
                   <Route path="presenting-complain/:id/edit" element={<EditPresentingComplain />} />

                  <Route path="medication/:type" element={<ManageMedication />} />
                  <Route path="medication/add" element={<AddMedication />} />
                  <Route path="medication/:id/edit" element={<EditMedication />} />
                  <Route path="medication/csv" element={<MedicationCsv />} />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Layout;

import { useEffect, useState } from "react";
import moment from 'moment';
import DetailModal from "../../components/Common/Modals/detail";
import CommonEmailPage from "../../components/Common/CommonEmailPage";
import { toast } from "react-hot-toast";
import ConfirmAlert from "../../components/Common/Modals/ConfirmAlert";
import { updateReportStatus } from "../../actions/reports";

function TableData({ srno, index, data, reload }) {
  // local variables
  const [status, setStatus] = useState(data?.status);
  const [showDetail, setShowDetail] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);

  const updateStatus = async (data) => {
    try {
      await updateReportStatus({ id: data?.id, status: 1 });
      setStatus(1);
      toast.success("Status Updated Successfully");
    } catch (error) {
      toast.error(`${error?.response?.data?.message}`);
    }
  };

  //   use hooks
  useEffect(() => {
    setStatus(data?.status);
  }, [data.status]);

  return (
    <>
      <tr className="modal-tr">
        <td>{parseInt(srno + parseInt(index + 1))}</td>
        <td>
          <a
            className="table-modal only-link"
            href="javascript:;"
            onClick={() => {
              setShowDetail(true);
            }}
          >
            {data?.title}
          </a>
        </td>
        {data?.species !== null && <td>{data.species}</td>}
        {data?.microchip !== null && <td>{data.microchip}</td>}
        <td>{data?.User?.UserProfile?.name ?? null}</td>
        <td>{data?.User?.email ?? null}</td>
        <td>
          {data?.User?.countryCode ?? null} {data?.User?.phoneNumber ?? null}
        </td>
        <td>
          {data?.createdAt ? moment(data?.createdAt).format("lll") : null}
        </td>

        <td>
          {status ? (
            <div className="status-ui">Resolved</div>
          ) : (
            <a
              href="javascript:void(0)"
              className="status-ui"
              onClick={() => {
                setConfirmShow(true);
              }}
            >
              Not resolved
            </a>
          )}
        </td>

        <td>
          <a
            class="sb-clnt"
            href="javascript:void(0)"
            onClick={() => {
              setShowEmail(true);
            }}
          >
            Reply
          </a>
        </td>
      </tr>

      {showEmail ? (
        <CommonEmailPage
          show={showEmail}
          onHide={() => {
            setShowEmail(false);
          }}
          data={data}
          email={data?.User?.email}
          title={data?.title}
        />
      ) : null}

      {showDetail ? (
        <DetailModal
          show={showDetail}
          onHide={() => {
            setShowDetail(false);
          }}
          data={data}
          title={"Report Detail"}
        />
      ) : null}

      {confirmShow ? (
        <ConfirmAlert
          show={confirmShow}
          onHide={() => {
            setConfirmShow(false);
          }}
          title="Are you sure to mark this report resolved?"
          confirmButtonName={"Yes"}
          cancelButtonName={"No"}
          onButtonClick={() => {
            updateStatus(data);
          }}
        />
      ) : null}
    </>
  );
}

export default TableData;

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";

import Inputfield from "../../../components/FormFields/InputField";
import ReactSelectField from "../../../components/FormFields/ReactSelectField";
import { genderTypes, professionalType } from "../../../constants";
import ReactPhoneInput from "../../../components/FormFields/ReactPhoneInput";

function EditVet() {
  // use hooks
  const {
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const { id } = useParams();

  // on form submissions
  const onSubmit = (formData) => {
    // console.log(formData);
  };

  const getVetById = async () => {
    try {
      // const resp = await getVetDataById();
      const resp = {};
      setValue("name", resp?.data?.responseData?.name || "Sukriti");
      setValue("email", resp?.data?.responseData?.email || "sukriti@gmail.com");
      setValue(
        "phone",
        resp?.data?.responseData?.phone || "7654345678"
      );
      setValue(
        "countryCode",
        resp?.data?.responseData?.countryCode || "98"
      );
      setValue("address", resp?.data?.responseData?.address || "Chandigarh");
      setValue("gender", resp?.data?.responseData?.gender || "2");
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  };

  // usehooks
  useEffect(() => {
    if (id) {
      getVetById(id);
    }
  }, [id]);

  return (
    <>
      <div className="container ani-ui-block shop-manager">
        <div className="row menu-top-block">
          <div className="col-sm-12 tl-block align-self-center">
            <h4 className="heading">Edit Veterinarian Details</h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              {/* {detail === null && <div className="content-loader"></div>} */}
              <div className="col-lg-12 col-md-12">
                <div className="add-left-block">
                  <div className="fields-ui-block">
                    <div className="basic-details">
                      <div className="heading">
                        <h4>Personal Informations</h4>
                      </div>
                      <div className="form-block">
                        <div className="row">
                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              label="Full Name"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              name="name"
                              type="text"
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Full Name is required",
                                },
                                maxLength: {
                                  value: 40,
                                  message: "Maximum 40 characters are allowed!",
                                },
                              }}
                            />
                          </div>
                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              label="Email"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              name="email"
                              type="text"
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Email is required",
                                },
                                pattern: {
                                  value:
                                    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                                  message: "Please enter valid email address",
                                },
                              }}
                            />
                          </div>
                          <div className="col-lg-4">
                            <ReactPhoneInput
                              control={control}
                              phoneNumber={{
                                phoneName: "phone",
                                phoneRules: {
                                  required: {
                                    value: true,
                                    message: "Phone Number is required",
                                  },
                                  minLength: {
                                    value: 8,
                                    message:
                                      "Phone Number should be atleast 6 digits",
                                  },
                                },
                                defaultPhoneValue: "",
                              }}
                              countryCode={{
                                countryCodeName: "countryCode",
                                defaultValue: "",
                                countryCodeRules: {
                                  required: {
                                    value: true,
                                    message: "CountryCode is required",
                                  },
                                },
                                defaultCountryCodeValue: "",
                              }}
                              name="phone"
                              label="Phone no."
                              placeholder="Enter Phone Number"
                              defaultCountry={"sg"}
                              inputClass="phone-input"
                            />
                          </div>

                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              label="Address"
                              name="address"
                              type="text"
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Address is required",
                                },
                              }}
                            />
                          </div>

                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              label="Profile Pic"
                              name="addresss"
                              type="text"
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Address is required",
                                },
                              }}
                            />
                          </div>

                          <div className="col-lg-4">
                            <ReactSelectField
                              control={control}
                              name="gender"
                              placeholder="Select Gender"
                              label="Gender"
                              options={genderTypes}
                              optionValue="value"
                              optionLabel="label"
                              normalize={(value) => value?.id}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Gender is required",
                                },
                              }}
                            />
                          </div>

                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              label="NRIC Number"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              name="nricNumber"
                              type="number"
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "NRIC Number is required",
                                },
                              }}
                            />
                          </div>

                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              label="BIO"
                              name="bio"
                              type="text"
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "BIO is required",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="basic-details">
                      <div className="heading">
                        <h4>Professional Informations</h4>
                      </div>
                      <div className="form-block">
                        <div className="row">
                          <div className="col-lg-4">
                            <ReactSelectField
                              control={control}
                              name="professionalType"
                              placeholder="Select Type"
                              label="Type"
                              options={professionalType}
                              optionValue="value"
                              optionLabel="label"
                              normalize={(value) => value?.id}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Type is required",
                                },
                              }}
                            />
                          </div>
                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              label="Clinic Name"
                              name="clinicName"
                              type="text"
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Clinic Name is required",
                                },
                              }}
                            />
                          </div>
                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              label="License Number/ Registration Number"
                              name="registrationNumber"
                              type="text"
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message:
                                    "License Number/ Registration Number is required",
                                },
                              }}
                            />
                          </div>

                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              label="Documents"
                              name="address"
                              type="text"
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "documents is required",
                                },
                              }}
                            />
                          </div>

                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              label="Digital Signature"
                              name="digitalSignature"
                              type="text"
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Digital Signature is required",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row save-button-block">
                    <div className="col-sm-12 align-self-center">
                      <div className="btn-wrpr">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className={`btn-full orange-btn ${
                            isSubmitting && "btn-loader"
                          }`}
                        >
                          Submit details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default EditVet;

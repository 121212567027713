import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from 'react-hot-toast';

import Inputfield from "../../components/FormFields/InputField";
import { login } from "../../actions/auth";

function LoginForm() {
  // use Hooks
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const navigate = useNavigate();

  const onSubmit = async(formValues) => {
    try {
      const resp = await login({
       ...formValues
      });
      if (resp.status === 200) {
        let token = resp?.data?.responseData?.token;
        let permissions = resp?.data?.responseData?.Permissions;
        let role = resp?.data?.responseData?.Role;
        let userName = resp?.data?.responseData?.UserProfile?.name;

        /* Set User's Token in Local Storage */
        if (token) localStorage.setItem("jwtToken", token);
        if (userName) localStorage.setItem("userName", userName);

        /* Set User Type in Local Storage */
        if (role) localStorage.setItem("role", role);

        /* Set User Permissions in Local Storage */
        if (permissions)
          localStorage.setItem("userPermissions", JSON.stringify(permissions));

        toast.success("Logged in successfully");
        if (token) {
          navigate("/dashboard");
        }
      } else {
        if (resp?.data?.responseData?.message)
          toast.error(resp?.data?.responseData?.message);
        else if (resp?.data?.message) toast.error(resp?.data?.message);
        else toast.error("Something went wrong");
      }
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  };

  return (
    <>
      <div className="form-block-section">
        <form className="p-t-15" onSubmit={handleSubmit(onSubmit)}>
          <Inputfield
            control={control}
            name={"email"}
            placeholder="Email address"
            normalize={(e) => e.replace(/^\s+/g, "")}
            type="email"
            inputClassName={"textfield"}
            rules={{
              required: { value: true, message: "Email is required" },
              pattern: {
                value:
                  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                message: "Please enter valid email address",
              },
            }}
          />

          <Inputfield
            control={control}
            name={"password"}
            label={"Password"}
            placeholder="Password"
            normalize={(e) => e.replace(/^\s+/g, "")}
            type="password"
            inputClassName={"textfield"}
            rules={{
              required: { value: true, message: "Password is required" },
              minLength: {
                value: 4,
                message: "Password must be more than four characters",
              },
            }}
          />

          <button
            type="submit"
            disabled={isSubmitting}
            className={`btn-primary-full ${
              isSubmitting ? "btn-loader" : ''
            }`}
          >
            LOGIN NOW{" "}
          </button>

          <Link
            style={{
              fontSize: "14px",
              color: "#8A8E94",
              fontWeight: "bold",
              letterSpacing: "0.2px",
              textTransform: "uppercase",
              display: "block",
              textAlign: "center",
            }}
            to="/forgot-password"
          >
            Forgot Password?
          </Link>
        </form>
      </div>
    </>
  );
}

export default LoginForm;

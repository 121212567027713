import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import ToolTip from "../../../components/Common/ToolTip";
import ConfirmAlert from "../../../components/Common/Modals/ConfirmAlert";
import { deleteMedication } from "../../../actions/animal";

function ManageMedicationSlide({ srno, index, data, reload, type }) {

  // usehooks
  const { id } = useParams();
    //   local variables
  const [status, setStatus] = useState(data?.status);
  const [confirmShow, setConfirmShow] = useState(false);
  const [deleteBtnClicked, setDeleteBtnClicked] = useState(false);

  const deleteRecord = async () => {
    try {
      await deleteMedication(data?.id);
      if(reload) reload();
      toast.success("Medication has been deleted successfully");
    } catch (error) {
      toast.error(`${error?.response?.data?.message}`);
    }
  };

  return (
    <>
      <tr className="modal-tr">
        <td>{parseInt(srno + parseInt(index + 1))}</td>
        <td>
          {
            type == 'admin'
            ?
            data?.User?.data?.name ?? null
            :
            data?.User?.data?.UserProfile?.name ?? null
          }
        </td>
        <td>{data?.name}</td>
        <td>{data?.code}</td>
        <td>{data?.category}</td>
        <td>{data?.type}</td>
        <td>{data?.brand}</td>
        <td>{data?.supplier}</td>
        <td>{data?.defaultDose}</td>
        <td>{data?.defaultDays}</td>
        <td>{data?.defaultFrequency}</td>
        <td>{data?.meals}</td>
        <td>
          <div className="table-btn-block">
            <div className="table-btn-block">
              <Link
                className="btn edit-btn"
                to={`/dashboard/medication/${data?.id}/edit`}>
                Edit
              </Link>

              <button
                className="btn delete-btn"
                onClick={() => {
                  setDeleteBtnClicked(true);
                  setConfirmShow(true);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </td>
      </tr>
      {confirmShow ? (
        <ConfirmAlert
          show={confirmShow}
          onHide={() => {
            setConfirmShow(false);
          }}
          title={
            deleteBtnClicked
              ? "Are you sure you want to delete this Medication?"
              : status === 0
              ? "Are you sure to activate plan?"
              : "Are you sure to deactivate plan?"
          }
          confirmButtonName={"Yes"}
          cancelButtonName={"No"}
          onButtonClick={() => { deleteRecord(id) }}
        />
      ) : null}
    </>
  );
}

export default ManageMedicationSlide;

import { useCallback, useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { toast } from 'react-hot-toast';
import queryString from "query-string";
import { useLocation } from "react-router-dom";

import { limit } from "../../constants";
import TableData from "./TableData";
import Ticketfilters from "./TicketFilters";

function Ticket() {
    // use hooks
    const { search } = useLocation();

  // local variables
  const [list, setList] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [filters, setFilters] = useState(queryString.parse(search));

  const datas = {
    data: [
      {
        id: 1,
        cName: "Sukriti",
        cEmail: "sukriti@gamil.com",
        vName: "Priya",
        vEmail: "priya@illuminz.com",
        date: "01-01-2022",
        status: 1,
      },
    ],
    perPage: 10,
    totalRecords: 1,
  };

  const fetchData = useCallback(() => {
    setList(datas);
  try {
    // const resp = await getUsers({ ...filters, page, role: 2 });
    // dispatch({ type: USERS, payload: resp?.data?.responseData });
  } catch ({ response, request }) {
    if (response) {
      toast.error(response?.data?.message);
    } else if (request) {
      toast.error("You do not have internet connection");
    }
  }
},[]);

const handlePageChange = (page) => {
  // dispatch({ type: USERS, payload: null });
  setActivePage(page);
};

const getFilterFields = (filters) => {
  // dispatch({ type: USERS, payload: null });
  setActivePage(1);
  setFilters(filters);
};

// use hooks
useEffect(() => {
  fetchData();
}, [fetchData, activePage, filters]);

  return (
    <>
      <div className="right-ui-block">
        <div className="scrollspy-example">
          <div className="rm-content">
            <div className="row menu-top-block">
              <div className="col-sm-5 tl-block align-self-center">
                <h4 className="heading">
                  Tickets ({list?.totalRecords ? list.totalRecords : 0})
                </h4>
              </div>

              <div className="col-sm-7 tr-block text-right align-self-center">
              </div>
            </div>
            <Ticketfilters getFilterFields={getFilterFields} />
            <div className="row">
              <div className="col-sm-12">
                <div className="result-listing">
                  <div className="table-responsive">
                    <table>
                      <thead>
                        <tr>
                          <th>Sr.no.</th>
                          <th className="manage-content">Customer Name</th>
                          <th className="manage-content">Customer Email</th>
                          <th className="manage-content">Veterinarians Name</th>
                          <th className="manage-content">Veterinarians Email</th>
                          <th className="manage-content">Date</th>
                          <th className="manage-content">Status</th>
                          <th className="manage-content text-center">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {list === null ? (
                          <tr>
                            <td colSpan={10} align="center">
                              <div className="table-loader" />
                            </td>
                          </tr>
                        ) : list?.data?.length > 0 ? (
                          list?.data?.map?.((obj, index) => (
                            <TableData
                              key={obj.id}
                              data={obj}
                              index={index}
                              srno={(activePage - 1) * limit}
                              reload={() => fetchData(activePage, "filters")}
                            />
                          ))
                        ) : (
                          <tr>
                            <td
                              className="no-record"
                              colSpan={10}
                              align="center"
                            >
                              No record found.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {list?.data?.length > 0 && (
                    <div className="pagination">
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={
                            list?.perPage ? list?.perPage : limit
                        }
                        totalItemsCount={
                            list?.totalRecords ? list?.totalRecords : 0
                        }
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Ticket;

import { useLocation } from "react-router";

function Loader() {
  const location = useLocation();
  const url = location.pathname;

  return (
    <>
      <div
        className={
          url.includes("banner") ||
          url.includes("brands") ||
          url.includes("categories") ||
          url.includes("brand-list") ||
          url.includes("vendors") ||
          url.includes("loginBanner") ||
          url.includes("category")||
          url.includes("blogs") ||
          url.includes("settings") ||
          url.includes("teams") ||
          url.includes("career-setting")
            ? "inLoader sm field-loader"
            : "inLoader my-4"
        }
      >
        <div className="">
          <span className="circle circle-1"></span>
          <span className="circle circle-2"></span>
          <span className="circle circle-3"></span>
          <span className="circle circle-4"></span>
          <span className="circle circle-5"></span>
        </div>
      </div>
    </>
  );
}

export default Loader;

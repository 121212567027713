import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import ConfirmAlert from "../../../components/Common/Modals/ConfirmAlert";
import {rolesArrayList} from "../../../constants";
import {deleteClinicMember} from "../../../actions/Users";

function StaffData({ srno, index, data, reload}) {
  const [confirmShow, setConfirmShow] = useState(false);
  //local variables
  const deleteRecord = async () => {
    try {
      await deleteClinicMember({ id: data?.id });
      reload();
      toast.success("Clinic has been deleted successfully");
    } catch (error) {
      toast.error(`${error?.response?.data?.message}`);
    }
  };

  return (
    <>
     <tr className="modal-tr">
        <td>{ parseInt(index + 1) }</td>
        <td>{data?.name ?? null}</td>
        <td>{data?.email ?? null}</td>
        <td>{data?.countryCode} {data?.phoneNumber}</td>
        <td>{data?.role ? rolesArrayList[data?.role-1].name : null}</td>
        <td><div className="table-btn-block"><button className="btn delete-btn" onClick={() => { setConfirmShow(true); }} > Delete </button></div></td>
      </tr>

      {
        confirmShow ? (
        <ConfirmAlert
          show={confirmShow}
          onHide={() => {
            setConfirmShow(false);
          }}
          title="Are you sure you want to delete this staff member?"
          confirmButtonName={"Yes"}
          cancelButtonName={"No"}
          onButtonClick={() => deleteRecord() }
        />
      ) : null}
    </>
      
  );
}

export default StaffData;

import axios from "axios";

export function getAllAnimals(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/pet'
    })
}

export function updatePetStatus(payload){
    return axios({
        method: 'PATCH',
        data: payload,
        url: '/pet'
    })
}


export function getAllBreeds(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/bred'
    })
}

export function BreedAdd(payload){
    return axios({
        method: 'POST',
        data: payload,
        url: '/bred'
    })
}

export function getBreedById(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/bred/id'
    })
}

export function BreedDelete(payload){
    return axios({
        method: 'DELETE',
        data: payload,
        url: '/bred'
    })
}

export function UpdateBreedStatus(payload){
    return axios({
        method: 'PATCH',
        data: payload,
        url: '/bred'
    })
}

export function getAllSpecies(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/species'
    })
}

export function getSpeciesById(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/species/id'
    })
}

export function SpeciesAdd(payload){
    return axios({
        method: 'POST',
        data: payload,
        url: '/species'
    })
}

export function SpeciesDelete(payload){
    return axios({
        method: 'DELETE',
        data: payload,
        url: '/species'
    })
}

export function UpdateSpeciesStatus(payload){
    return axios({
        method: 'PATCH',
        data: payload,
        url: '/species'
    })
}

export function uploadCsvSpices(payload){
    return axios({
        method: 'POST',
        data: payload,
        url: '/admin/venomSheet'
    })
}

export function getAnimalDetail(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/pet/id'
    })
}

export function uploadCsvVenomSpices(payload){
    return axios({
        method: 'POST',
        data: payload,
        url: '/admin/venomSheet'
    })
}

export function getAllDiagnosis(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/diagnosis'
    })
}
export function getAllDiagnosisTest(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/diagnosticTest'
    })
}
export function getVisitReason(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/visitReason'
    })
}
export function getPresentialComplain(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/presentingComplaint'
    })
}
export function getMedicationTypeById(payload, id){
    return axios({
        method: 'GET',
        params: payload,
        url: '/details/'+id
    })
}
export function updateMedicationType(params, id) {
    return axios({
        method: 'PATCH',
        data: params,
        url: 'details/'+id
    })
}

export function getAllMedication(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/medicine'
    })
}

export function addMedication(payload){
    return axios({
        method: 'POST',
        data: payload,
        url: '/medicine'
    })
}

export function editMedication(payload){
    return axios({
        method: 'PATCH',
        data: payload,
        url: '/medicine'
    })
}
export function getMedicationById(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/medicine/findById'
    })
}

export function uploadCsvMedication(payload){
    return axios({
        method: 'POST',
        data: payload,
        url: '/medicine/uploadCSV'
    })
}

export function deleteMedication(id){
    return axios({
        method: 'DELETE',
        // data: payload,
        url: '/medicine?id='+id
    })
}
import { Link } from "react-router-dom";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
// import defaultImg from '/images/about.png';
import ToolTip from "../../../../components/Common/ToolTip";
import ConfirmAlert from "../../../../components/Common/Modals/ConfirmAlert";
import { SpeciesDelete, UpdateSpeciesStatus } from "../../../../actions/animal";
import { BASE_URL } from "../../../../constants";

function TableData({ srno, index, data, reload }) {
    //   local variables
    const [status, setStatus] = useState(data?.status);
    const [confirmShow, setConfirmShow] = useState(false);
    const [deleteBtnClicked, setDeleteBtnClicked] = useState(false);
    // const [showDetail, setShowDetail] = useState(false);
  
    const updateUserStatus = async (data) => {
      try {
        await UpdateSpeciesStatus({ id: data?.id, status: status === 1 ? 0 : 1 });
        setStatus(status === 1 ? 0 : 1);
        toast.success("Status Updated Successfully");
      } catch (error) {
        toast.error(`${error?.response?.data?.message}`);
      }
    };
  
    const deleteRecord = async () => {
      console.log(typeof data?.id ,"fdbhdfbhgjvdfjh")
      try {
        await SpeciesDelete({ id: data?.id });
        if(reload) reload();
        toast.success("Species has been deleted successfully");
      } catch (error) {
        toast.error(`${error?.response?.data?.message}`);
      }
    };
    //   use hooks
    useEffect(() => {
      setStatus(data?.status);
    }, [data.status]);
  
    
  return (
    <>
      <tr className="modal-tr">
        <td>{parseInt(srno + parseInt(index + 1))}</td>
        <td>
        <div class="table-img-wrpr">
        <img className="img-fluid table-img" src={data?.attachment?.path !== undefined ? `${BASE_URL}${data?.attachment?.path}` : ""} alt="No Image found on server" onError={({currentTarget})=> {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src="/assets/images/no_image_available.jpeg";
        }}>
        </img>
          </div>
         </td>
        <td>
          <Link
            className="table-modal only-link"
            to={`/dashboard/species/${data?.id}/breeds`}
          >
            {data?.name}
          </Link>
        </td>
        <td>
            <div
              className="status-ui"
              onClick={() => {
                setDeleteBtnClicked(false);
                setConfirmShow(true);
              }}
            >
              {status === 1 ? (
                <ToolTip title="Click to deactivate">
                  <div className="stock-field on-btn">
                    <label className="switch">
                      <div className="switch-slider"></div>
                    </label>
                  </div>
                </ToolTip>
              ) : (
                <ToolTip title="Click to activate">
                  <div className="stock-field off-btn">
                    <label className="switch">
                      <div className="switch-slider"></div>
                    </label>
                  </div>
                </ToolTip>
              )}
            </div>
        </td>
        <td>
          <div className="table-btn-block">
            <div className="table-btn-block">
              <Link
                className="btn edit-btn"
                  to={`/dashboard/species/${data?.id}/edit`}
              >
                Edit
              </Link>

              <button
                className="btn delete-btn"
                onClick={() => {
                  setDeleteBtnClicked(true);
                  setConfirmShow(true);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </td>
      </tr>

      {confirmShow ? (
        <ConfirmAlert
          show={confirmShow}
          onHide={() => {
            setConfirmShow(false);
          }}
          title={
            deleteBtnClicked
              ? "Are you sure you want to delete this Species?"
              : status === 0
              ? "Are you sure to activate Species?"
              : "Are you sure to deactivate Species?"
          }
          confirmButtonName={"Yes"}
          cancelButtonName={"No"}
          onButtonClick={() => {
            deleteBtnClicked ? deleteRecord() : updateUserStatus(data);
          }}
        />
      ) : null}

    </>
  );
}

export default TableData;

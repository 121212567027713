import axios from "axios";
export const PLAN_DETAIL = "PLAN_DETAIL";
export const FEATURE_DETAIL = "FEATURE_DETAIL"

export function GetPlans(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: 'plan'
    })
}

export function GetPlansWithId(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: 'plan/id'
    })
}

export function DeleteFeature(payload){
    return axios({
        method: 'DELETE',
        params: payload,
        url: 'feature'
    })
}

export function GetFeatureById(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: 'feature/id'
    })
}

export function EditFeatureById(payload) {
    console.log("this is edit payload", payload)
    return axios({
        method: 'PATCH',
        data: payload,
        url: 'feature'
    })
}
export function AddNewFeature(payload) {
    console.log("this is add payload", payload)
    return axios({
        method: 'POST',
        data: payload,
        url: 'feature'
    })
}

export function PlanAdd(payload){
    return axios({
        method: 'POST',
        data: payload,
        url: 'plan'
    })
}

export function planDelete(payload){
    return axios({
        method: 'DELETE',
        params: payload,
        url: 'plan'
    })
}

export function editPlan(payload){
    return axios({
        method: 'POST',
        data: payload,
        url: 'plan'
    })
}

export function planStatus(payload){
    return axios({
        method: 'PATCH',
        data: payload,
        url: 'plan-status'
    })
}

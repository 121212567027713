import moment from "moment";
import { useEffect, useState, useCallback } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import { getClinics, getNotes, sendMoreDetailToVet, updateUserOnboardStatus } from "../../../actions/Users";
import DetailSlide from './DetailSlide';
import { Modal } from "react-bootstrap";

function Detail() {
  // local variables
  const [detail, setDetail] = useState(null);
  const [clinicList, setClinicList] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAskMoreDetailModal, setShowAskMoreDetailModal] = useState(false);
  const [moreDetail, setMoreDetail] = useState("");
  const [notes, setNotes] = useState([]);
  // use hooks
  const location = useLocation();

  const { id } = useParams();
  console.log("id *****",id);

  const fetchData = useCallback( async (userId) => {
   try {
      const resp = await getClinics({userId: id});
      setClinicList(resp?.data?.responseData);   
      
   } catch ({ response, request }) {
     if (response) {
       toast.error(response?.data?.message);
     } else if (request) {
       toast.error("You do not have internet connection");
     }
   }
  },[]);


  const handleApprove = async ()=> {
    await updateUserOnboardStatus({userId: id, status: 2});
  };

  const handleReject = async ()=> {
    await updateUserOnboardStatus({userId: id, status: 0});
  };

  const handleAskMoreDetail = async ()=> {
    setShowAskMoreDetailModal(true);
    setMoreDetail("");
  };

  const handleSubmitMoreDetail = async ()=> {
      await sendMoreDetailToVet({userId: id, note: moreDetail, isAdmin: 1});
      setShowAskMoreDetailModal(false);
      fetchNotes();
  }

  const fetchNotes = async ()=> {
    const response = await getNotes({userId: id});
    setNotes(response?.data?.responseData);
  }

  const onHide = async ()=> {
    setShowAskMoreDetailModal(false);
  };


  useEffect(() => {
    fetchNotes();
    fetchData(id);
    if (location.state) {
      setDetail(location?.state);
      
    }
    
  }, [location]);


  console.log(detail, '========', clinicList);



  return (
    <>
       <div className="row menu-top-block">

        <div className="data-header-left cr-operators">
          <div className="count-data">
            <span className="pr-page"><Link to="/dashboard/vets" className="link-hrf">Vets </Link> </span>
          </div>
          <div className="count-day lk-path">
            <span className="st-current">Clinic</span>
          </div>
        </div>


      </div>
      {
        detail?.UserProfile?.name
        ?
        <div className="container ani-ui-block shop-manager">
          <div className="row menu-top-block">
            <div className="d-flex w-100 align-items-center justify-content-between tl-block align-self-center">
              <h4 className="heading">Detail</h4>
              <div className="d-flex">
                
                      <button
                        onClick={handleApprove}
                        type="submit"       
                        className={`btn btn-success mx-2 ${
                          isSubmitting ? "btn-loader" : ''
                        }`}
                      >
                        Approve
                      </button>
                 
                  
                      <button
                        onClick={handleReject}
                        type="submit"
                        disabled={isSubmitting}
                        className={`btn btn-danger mx-2 ${
                          isSubmitting ? "btn-loader" : ''
                        }`}
                      >
                        Reject
                      </button>
                
                 
                      <button
                        type="submit"
                        onClick={handleAskMoreDetail}
                        disabled={isSubmitting}
                        className={`btn btn-info mx-2 ${
                          isSubmitting ? "btn-loader" : ''
                        }`}
                      >
                        Ask More Detail
                      </button>
                 
              </div>
            </div>
          </div>
          <div className="row menu-top-block">
            <div className="col-lg-12 col-md-12">
              <div className="add-left-block">
                <div className="fields-ui-block">
                  <div className="basic-details form-block">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="info-ui">
                          <label>Name:</label>
                          <p>{detail?.UserProfile?.name}</p>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="info-ui">
                          <label>Email Id:</label>
                          <p>{detail?.email}</p>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="info-ui">
                          <label>Gender</label>
                          <p>{detail?.UserProfile?.gender}</p>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="info-ui">
                          <label>Phone Number:</label>

                          <p>
                            {detail?.countryCode &&
                              (detail?.countryCode?.includes("+")
                                ? detail?.countryCode
                                : `+${detail?.countryCode}`)}
                            &nbsp;
                            {detail?.mobile
                              ? detail?.mobile
                              : detail?.phoneNumber
                              ? detail?.phoneNumber
                              : "N.A"}
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="info-ui">
                          <label>Country</label>
                          <p>{detail?.UserProfile?.country}</p>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="info-ui">
                          <label>Plan</label>
                          <p>{detail?.UserProfile?.Plan?.defaultContent[0]?.name ? detail?.UserProfile?.Plan?.defaultContent[0]?.name : "---"}</p>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="info-ui">
                          <label>Start date</label>
                          <p>{detail?.UserProfile?.startDate ? moment(detail?.UserProfile?.startDate).format("lll") : '---'}</p>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="info-ui">
                          <label>End date</label>
                          <p>{detail?.UserProfile?.endDate ? moment(detail?.UserProfile?.endDate).format("lll") : "---"}</p>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="info-ui">
                          <label>Onbarding Status:</label>

                          <p>
                              Approved
                          </p>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        null
      }
      <div className="row menu-top-block">
          <div className="col-sm-12 tl-block align-self-center">
            <h4 className="heading">Recent Notes</h4>
          </div>
      </div>
      <div className="bg-white mx-3 my-3 p-3 w-full rounded">
        {notes.map((note)=>         
          <div>
              <h4>{note?.isAdmin == 1 ? "Admin" : "Vets"} {`(${new Date(note?.createdAt).toLocaleDateString('en-US')})`}</h4>
              <p class="text-justify">{note?.note}</p>
          </div>)
        }
      </div>
      <div className="container ani-ui-block shop-manager">
        <div className="row menu-top-block">
          <div className="col-sm-12 tl-block align-self-center">
            <h4 className="heading">Clinic</h4>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-sm-12">
            <div className="result-listing">
              <div className="table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th>Sr.no.</th>
                      <th>Profile Image</th>
                      <th className="manage-content">Name</th>
                      <th className="manage-content">Phone Number</th>
                      <th className="manage-content">Address</th>
                      <th className="manage-content">Staff</th>
                      <th className="manage-content">License</th>
                      <th className="manage-content">Signature</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      clinicList?.clinics.length > 0 && clinicList?.clinics.map((obj, index) => (
                         <DetailSlide key={obj.id} slideData={obj} index={index} vetId={detail?.id} /> 
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showAskMoreDetailModal == true ? 
        <Modal
          show={showAskMoreDetailModal}
          onHide={onHide}
          className="modal fade"
          dialogClassName="modal-dialog modal-md"
        >
          <div className="modal-header">
            <div className="title-btn-wrpr">
              <h2 className="modal-title mr20">Ask more Detail to vet</h2>
            </div>
            <div className="table-btns modal-btns">
              <button type="button" className="close" onClick={onHide}></button>
            </div>
          </div>

          <div className="modal-body">
            <div class="mb-3">
            <textarea value={moreDetail} onChange={(e)=> setMoreDetail(e.target.value)} type="text" class="form-control" id="exampleInputText" placeholder="Add missing details"/>
          </div>
            <div className="row">
              <div className="col-6"></div>
              <div className="col-6 text-right">
                <button type="button" className="mr-2" onClick={handleSubmitMoreDetail}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Modal> 
        : null
      }

    </>
  );
}

export default Detail;

import axios from "axios";

export function getAllRoles(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/acl-role'
    })
}

export function getRoleWithId(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/role-permissions'
    })
}

export function postRole(payload){
    return axios({
        method: 'POST',
        data: payload,
        url: '/acl-role'
    })
}

export function deleteRole(payload){
    return axios({
        method: 'DELETE',
        params: payload,
        url: '/acl-role'
    })
}

export function patchRole(payload){
    return axios({
        method: 'PATCH',
        data: payload,
        url: '/acl-role'
    })
}

export function getAllPermissions(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/acl-permissions'
    })
}

export function getAllStaff(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/acl-users'
    })
}

export function getStaffWithId(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/acl-users-id'
    })
}

export function postStaff(payload){
    return axios({
        method: 'POST',
        data: payload,
        url: '/acl-users'
    })
}

export function deleteStaff(payload){
    return axios({
        method: 'DELETE',
        params: payload,
        url: '/acl-users'
    })
}

export function editStaff(payload){
    return axios({
        method: 'PATCH',
        data: payload,
        url: '/acl-users'
    })
}
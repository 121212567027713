import { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import CSVReader from "react-csv-reader";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { uploadCsvMedication } from "../../../actions/animal";

function MedicationCsv() {
  // use hooks
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm();

  const navigate = useNavigate();

  const [records, setRecords] = useState(null);
  let [message, setMessage] = useState('Select CSV with medication records');

  const handleForce = (data, fileInfo) => {
    setRecords(data);
  }

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header => header.replace(/\W/g, "_")
    // transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
  };

  // on form submissions
  const onSubmit = async (data) => {
    try {
      let result = await uploadCsvMedication({...data, csvRecords: records});
      navigate(-1);
      toast.success("Records has been updated successfully.");
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  };

return (
    <>
      <div className="container ani-ui-block shop-manager">
        <div className="row menu-top-block">
          <div className="col-sm-12 tl-block align-self-center">
            <h4 className="heading">
              Medication CSV
            </h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="add-left-block">
                  <div className="fields-ui-block">
                    <div className="basic-details">
                      <div className="heading">
                        <h4>Upload CSV</h4>
                      </div>
                      <div className="form-block">
                        <div className="row">
                          <CSVReader
                            cssClass="react-csv-input"
                            label={message}
                            onFileLoaded={handleForce}
                            parserOptions={papaparseOptions}
                          />
                          
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row save-button-block">
                    <div className="col-sm-12 align-self-center">
                      <div className="btn-wrpr">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className={`btn-full orange-btn ${
                            isSubmitting && "btn-loader"
                          }`}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default MedicationCsv;

import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmAlert from "../Common/Modals/ConfirmAlert";

function HeaderNavigation() {

  // local variables
  const [userName, setUserName] = useState("");
  const [confirmShow, setConfirmShow] = useState(false);

  // use hooks
  const navigate = useNavigate();

  const fetchUserName = () => {
    if (localStorage.getItem("userName")) {
      const userName = localStorage.getItem("userName");
      setUserName(userName);
    }
  };

  // on logout click
  const onButtonClick = async () => {
    localStorage.clear();
    navigate("/");
  }


  useEffect(() => {
    fetchUserName();
  }, []);

  return (
    <>
      <div className="nav-right">
        {/* {status === "offline" && showOnlineOfflineToast()} */}

        <div className="block-alert time-ui-block">
          <div className="time-text">
            <span>
              (
              {new Date().toString().split(" ")[5]
                ? new Date().toString().split(" ")[5]
                : ""}
              )
            </span>
          </div>
          <span className="date-text">
            {" "}
            {moment(new Date()).format("MM/DD/Y h:mm A")}
          </span>
        </div>

        <div className="block-alert account-nav">
          <a
            href="^"
            className="btn-dropdown dropdown-toggle"
            data-toggle="dropdown"
          >
            {/* <i className="material-icons">person</i> */}
            <span className="name">{ userName ? userName : 'Demo Name'}</span>
          </a>
          <div className="dropdown-menu">
            <a
              href="javascript:;"
              className="dropdown-item"
              onClick={setConfirmShow}
            >
              Logout
            </a>
          </div>
        </div>

        <div className="block-alert notification-nav">
          <a
            href="^"
            // onClick={() => setNotificationModal(true)}
            className="btn-dropdown"
          >
            <i className="material-icons">notifications</i>
          </a>
        </div>
      </div>

      {confirmShow ? (
        <ConfirmAlert
          show={confirmShow}
          onHide={() => {
            setConfirmShow(false);
          }}
          title="Are you sure you want to logout?"
          confirmButtonName={"Yes"}
          cancelButtonName={"No"}
          onButtonClick={onButtonClick}
        />
      ) : null}
    </>
  );
}

export default HeaderNavigation;

import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";

import Inputfield from "../../components/FormFields/InputField";
import InputTextAreaField from "../../components/FormFields/InputTextAreaField";
import { useEffect, useState } from "react";
import { getCurrency } from "../../actions/auth";
import ReactSelectField from "../../components/FormFields/ReactSelectField";
import { AddNewFeature, GetPlans } from "../../actions/plan";
import MultiSelectCheckboxField from "../../components/FormFields/MultiSelectCheckboxField";

function AddFeature() {
  // local varibales
  const [currency, setCurrency] = useState([]);
  // use hooks
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
      planPrice: [
        {
          currencyId: "",
          amount: "",
        },
      ],
    },
  });
  const [plans, setPlans] = useState([])
  const fetchPlan = async () => {
    try {
      const resp = await GetPlans({ status: 1});
      let tempPlanAry = resp?.data?.responseData.plans.map((plan) => ({
        value: plan.mainContent.planId,
        name: plan.mainContent.name
      }))
      setPlans(tempPlanAry);
      console.log("this is plan list", resp?.data?.responseData)
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  };
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "planPrice", // unique name for your Field Array
  });

  const navigate = useNavigate();

  // on form submissions
  const onSubmit = async (formData) => {
    console.log(formData, "formData");
    try {
      await AddNewFeature({name: formData.name, description: formData.description, plans: formData.plans });
      navigate('/dashboard/plans');
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  };

  useEffect(() => {
    fetchPlan();
  }, []);

  return (
    <>
      <div className="container ani-ui-block shop-manager">
        <div className="row menu-top-block">
          <div className="col-sm-12 tl-block align-self-center">
            <h4 className="heading">Add Feature</h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="add-left-block">
                  <div className="fields-ui-block">
                    <div className="basic-details">
                      <div className="heading">
                        <h4>Basic Details</h4>
                      </div>
                      <div className="form-block">
                        <div className="row">
                          <div className="col-lg-6">
                            <Inputfield
                              control={control}
                              label="Name*"
                              name="name"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              type="text"
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Name is required",
                                },
                                maxLength: {
                                  value: 40,
                                  message: "Maximum 40 characters are allowed!",
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <InputTextAreaField
                              control={control}
                              name="description"
                              label={"Description"}
                              placeholder="Description"
                              type="text"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Description is required",
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <MultiSelectCheckboxField
                              control={control}
                              name="plans"
                              label={"Plans"}
                              placeholder="Plans"
                              options={plans}
                              optionValue="value"
                              optionLabel="name"
                              normalize={(_value) => _value?.value}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Plans is required",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row save-button-block">
                    <div className="col-sm-12 align-self-center">
                      <div className="btn-wrpr">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className={`btn-full orange-btn ${
                            isSubmitting && "btn-loader"
                          }`}
                        >
                          Add New Features
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddFeature;

import axios from "axios";

export function getAllReportedIssues(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/report'
    })
}

export function updateReportStatus(payload){
    return axios({
        method: 'PATCH',
        data: payload,
        url: '/report'
    })
}


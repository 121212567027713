import { Link } from "react-router-dom";
import moment from "moment";
import toast from 'react-hot-toast';
import { useState } from "react";

import ConfirmAlert from "../../../components/Common/Modals/ConfirmAlert";
import { deleteStaff } from "../../../actions/acl";

function TableData({ srno, index, data, reload }) {

    // local variables
    const [confirmShow, setConfirmShow] = useState(false);

    const deleteRecord = async () => {
      try {
        await deleteStaff({ id: data?.id });
        toast.success("Staff has been deleted successfully");
        reload();
      } catch (error) {
        toast.error(`${error?.response?.data?.message}`);
      }
    };

    console.log('aaaa', data);

  return (
    <>
      <tr className="modal-tr">
        <td>{parseInt(srno + parseInt(index + 1))}</td>
        <td>
          <a
            className="table-modal only-link"
            href="javascript:;"
          >
            {data?.UserProfile?.name}
          </a>
        </td>
        <td>{data?.email}</td>
        {/* <td>
          {data?.countryCode &&
            (data?.countryCode?.includes("+")
              ? data?.countryCode
              : `+${data?.countryCode}`)}
          &nbsp;
          {data?.mobile ? data?.mobile : "N.A"}
        </td> */}
        <td>
          <ul>
          {
            data?.Roles.length > 0 && data?.Roles?.map((obj, index) => (
              <li>{obj.name}</li>
            ))
          }
          </ul>
        </td>
        {/* <td>
          {data?.createdAt ? moment(data?.createdAt).format("lll") : "---"}{" "}
        </td> */}
        {/* <td>
            <div
              className="status-ui"
              onClick={() => updateUserStatus(status === 0 ? 2 : 1)}
            >
              {status === 0 ? (
                <ToolTip title="Click to deactivate">
                  <div className="stock-field on-btn">
                    <label className="switch">
                      <div className="switch-slider"></div>
                    </label>
                  </div>
                </ToolTip>
              ) : (
                <ToolTip title="Click to activate">
                  <div className="stock-field off-btn">
                    <label className="switch">
                      <div className="switch-slider"></div>
                    </label>
                  </div>
                </ToolTip>
              )}
            </div>
        </td> */}
        <td>
          <div className="table-btn-block">
            <div className="table-btn-block">
              <Link
                className="btn edit-btn"
                  to={`/dashboard/staff/${data?.id}/edit`}
              >
                Edit
              </Link>

              <button
                className="btn delete-btn"
                onClick={() => setConfirmShow(true)}
              >
                Delete
              </button>
            </div>
          </div>
        </td>
      </tr>

      {confirmShow ? (
        <ConfirmAlert
          show={confirmShow}
          onHide={() => {
            setConfirmShow(false);
          }}
          title={
              "Are you sure you want to delete this Staff?"
          }
          confirmButtonName={"Yes"}
          cancelButtonName={"No"}
          onButtonClick={() => {
            deleteRecord()
          }}
        />
      ) : null}
    </>
  );
}

export default TableData;

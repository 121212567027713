import { useForm } from "react-hook-form";
import Inputfield from "../../../components/FormFields/InputField";

function EditCustomer() {
  // use hooks
  const {
    control,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm();

  // on form submissions
  const onSubmit = (formData) => {
    // console.log(formData);
  };

  return (
    <>
      <div className="container ani-ui-block shop-manager">
        <div className="row menu-top-block">
          <div className="col-sm-12 tl-block align-self-center">
            <h4 className="heading">Edit Customer Details</h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              {/* {detail === null && <div className="content-loader"></div>} */}
              <div className="col-lg-12 col-md-12">
                <div className="add-left-block">
                  <div className="fields-ui-block">
                    <div className="basic-details">
                      <div className="heading">
                        <h4>Basic Details</h4>
                      </div>
                      <div className="form-block">
                        <div className="row">
                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              label="Name"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              name="name"
                              type="text"
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Name is required",
                                },
                                maxLength: {
                                    value: 40,
                                    message:
                                      "Maximum 40 characters are allowed!",
                                  }
                              }}
                            />
                          </div>
                          <div className="col-lg-4">
                          <Inputfield
                              control={control}
                              label="Email"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              name="email"
                              type="text"
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Email is required",
                                },
                              }}
                            />
                          </div>
                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              label="Phone Number"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              name="phoneNumber"
                              type="text"
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Phone Number is required",
                                },
                              }}
                            />
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row save-button-block">
                    <div className="col-sm-12 align-self-center">
                      <div className="btn-wrpr">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className={`btn-full orange-btn ${
                            isSubmitting && "btn-loader"
                          }`}
                        >
                          Submit details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default EditCustomer;

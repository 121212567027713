import { useController } from "react-hook-form";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { errorType } from "../../constants";

function InputTagField({
  control,
  rules,
  name,
  defaultValue,
  placeholder,
  type,
  inputClassName,
  label,
  onChange,
  errorClass,
  mainClass,
}) {
  // usehooks
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });

  // on input change
  const handleChange = (_tags) => {
    field.onChange(_tags);
  };

  return (
    <>
      <div className="form-group">
        {label && <label>{label}</label>}
        <div className={`textfield-block ${mainClass ? mainClass : ''}`}>
          <TagsInput
            name={name}
            className={inputClassName ? inputClassName : "textfield"}
            placeholder={placeholder}
            value={field.value ? field.value : []}
            onChange={(_tags) => {
              handleChange(_tags);
            }}
          />

          {errorType?.map((type) => {
            if (error?.type === type && error?.message !== "") {
              return (
                <span key={type} className={errorClass || "error-msg"}>
                  {error?.message}
                </span>
              );
            }
          })}
        </div>
      </div>
    </>
  );
}

export default InputTagField;

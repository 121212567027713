import axios from "axios"

export function getCaregiverRole(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/caregiver-role'
    })
}

export function addCaregiverRole(payload){
    return axios({
        method: 'POST',
        data: payload,
        url: '/caregiver-role'
    })
}

export function editCaregiverRole(payload){
    return axios({
        method: 'POST',
        data: payload,
        url: '/caregiver-role'
    })
}

export function deleteCaregiverRole(payload){
    return axios({
        method: 'DELETE',
        params: payload,
        url: '/caregiver-role'
    })
}
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from 'react-hot-toast';

import { forgotPassword } from "../../actions/auth";
import Inputfield from "../../components/FormFields/InputField";

function ForgotPasswordForm() {

  // use hooks
  const { control, handleSubmit, formState: { isSubmitting } } = useForm();

  // on Form submit
  const onSubmit = async(formValues) => {
    try {
      const resp = await forgotPassword({
       ...formValues
      });
      toast.success('Link sent to your registered email id');
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
    // navigate("/dashboard");
  };

  return (
    <>
      <div className="form-block-section" style={{ textAlign: "center" }}>
        <form className="p-t-15" onSubmit={handleSubmit(onSubmit)}>
        <Inputfield
            control={control}
            name={"email"}
            placeholder="Email address"
            normalize={(e) => e.replace(/^\s+/g, "")}
            type="email"
            inputClassName={"textfield"}
            rules={{
              required: { value: true, message: "Email is required" },
              pattern: {
                value:
                  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                message: "Please enter valid email address",
              },
            }}
          />

          <button type="submit"
          disabled={isSubmitting}
          className={`btn-primary-full ${
            isSubmitting ? "btn-loader" : ''
          }`}
          >
            Submit
          </button>
          <Link
            style={{
              fontSize: "14px",
              color: "#8A8E94",
              fontWeight: "bold",
              letterSpacing: "0.2px",
              textTransform: "uppercase",
            }}
            to="/"
          >
            Login
          </Link>
        </form>
      </div>
    </>
  );
}

export default ForgotPasswordForm;

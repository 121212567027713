import ForgotPasswordForm from "./ForgotPasswordForm";

function ForgotPassword() {
  return (
    <>
      <div style={{ height: "100%" }}>
        <div className="login-page">
          <div className="login-logo">
          <img src="/assets/images/logo.png" alt="Kinn" />
          </div>
          <div className="login-head">
            <h2>
              <span>Admin Portal</span>
            </h2>
            <p>Please enter your email to reset your password</p>
          </div>
          <ForgotPasswordForm />
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;

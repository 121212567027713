import { useController } from "react-hook-form";
import {useState, useEffect} from 'react';
import { errorType } from "../../constants";


function InputNumberField({
  control,
  rules,
  name,
  defaultValue,
  placeholder,
  type,
  label,
  onChange,
  autocomplete,
  mainClass,
  formInWidth,
  isMax,
  normalize,
  units,
  inputClass,
  maxLength
}) {
  // usehooks
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });

  const [value, setValue] = useState(field?.value ?? '')

  const [errorMessage,setErrorMessage] =useState("")
  useEffect(() => {
    if (/^\d*\.?\d*$/.test(field?.value)) {
      setValue(field?.value);
    }
  }, [field?.value])

  //   on change Input field
  // const onInputValueChange = (e) => {
  //   const {value} = e.target;
  //   if (/^\d*\.?\d*$/.test(value)) {
  //     setValue(value);
  //   }
  // };

  //   on change Input field
  const onInputChange = (e) => {
    // send value to onChange function
    if (onChange) onChange(normalize(e.target.value.replace(/  +/g, " ")));

    // send value to hook form
    field.onChange(normalize(e.target.value.replace(/  +/g, " ")));
  };

  // on Key down
  const onInputKeyDown = (e) => {
    if (type && type == "number") {
      if (e.keyCode == "189" || e.keyCode == "69") {
        e.preventDefault();
      }
    }
  };

  const onInputBlur = (e) => {
    setValue(e.target.value.replace(/\.$/, ''))
  };
  console.log(error,"vhvhjb")
  const errorMessageParts = error?.message?.split('"');
  useEffect(()=>{
    if (Array.isArray(errorMessageParts) && errorMessageParts.length > 0) {
      const lastPart = errorMessageParts[errorMessageParts.length - 1];
      setErrorMessage("This field is required")
      console.log(lastPart,"vhbjhvghj");
    } else {
      console.error('Error message structure is unexpected:', error?.message);
      // Handle the unexpected error message structure, or add additional logging as needed
    }
  },[error])

  return (
    <>
      <div
        className="form-group">
        {label && <label >{label}</label>}
        <div className="form-group">
          <input
            {...field}
            name={name}
            autoComplete={autocomplete || "on"}
            className={inputClass ? inputClass + " form-control" : "form-control"}
            min={type ? 1 : ""}
            onBlur={onInputBlur}
            max={type && isMax ? "9999" : ""}
            placeholder={placeholder}
            type={type ? type : "text"}
            value = {value}
            maxLength={maxLength}
            //onInput={onInputValueChange}
            onChange={(e) => onInputChange(e)}
          />
          {
            units && <span className="units">{units}</span>
          }
        </div>
        {errorType?.map((type) => {
          if (error?.type === type && error?.message !== "") {
            return (
              <p key={type} className="error">
                {error?.message}
              </p>
            );
          }
        })}

{
          error?.type==='custom' &&   <p key={type} className="error">
          {errorMessage}
        </p>
        }
      </div>
    </>
  );
}

/**
 * @property defaults
 */
InputNumberField.defaultProps = {
  defaultValue: "",
  rules: {},
  errorClass: "error",
  onChange: (value) => value,
  normalize: (value) => value,
};

export default InputNumberField;

import moment from "moment";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import ConfirmAlert from "../../components/Common/Modals/ConfirmAlert";

function TableData({ srno, index, data, reload }) {
  //   local variables
  const [status, setStatus] = useState(data?.status);
  const [confirmShow, setConfirmShow] = useState(false);
  const [deleteBtnClicked, setDeleteBtnClicked] = useState(false);
  //  const [showDetail, setShowDetail] = useState(false);

  const updateUserStatus = async (data) => {
    try {
      // await updateStatus({ userId: data?.id, status });
      setStatus(status === 1 ? 0 : 1);
      toast.success("Status Updated Successfully");
    } catch (error) {
      toast.error(`${error?.response?.data?.message}`);
    }
  };

  const deleteRecord = async () => {
    try {
      // await deleteUser({ userId: data?.id });
      toast.success("Vet has been deleted successfully");
    } catch (error) {
      toast.error(`${error?.response?.data?.message}`);
    }
  };

  //   use hooks
  useEffect(() => {
    setStatus(data?.status);
  }, [data.status]);

  return (
    <>
      <tr className="modal-tr">
        <td>{parseInt(srno + parseInt(index + 1))}</td>
        <td>
          {/* <a
            className="table-modal only-link"
            href="javascript:;"
            // onClick={() => {
            //   setUserId(obj?.id);
            //   setDetail(true);
            // }}
          >
            {data?.name}
          </a> */}
          {data?.title}
        </td>
        {/*<td>{maskEmailAddress(obj?.email)}</td>*/}
        <td>{data?.description}</td>
        <td>{data?.date ? moment(data?.date).format("lll") : "---"} </td>
        <td>{data?.sendTo}</td>
        {/* <td>
            <div
              className="status-ui"
              onClick={() => updateUserStatus(status === 0 ? 2 : 1)}
            >
              {status === 0 ? (
                <ToolTip title="Click to deactivate">
                  <div className="stock-field on-btn">
                    <label className="switch">
                      <div className="switch-slider"></div>
                    </label>
                  </div>
                </ToolTip>
              ) : (
                <ToolTip title="Click to activate">
                  <div className="stock-field off-btn">
                    <label className="switch">
                      <div className="switch-slider"></div>
                    </label>
                  </div>
                </ToolTip>
              )}
            </div>
        </td> */}
        <td>
          <div className="table-btn-block">
            <div className="table-btn-block">
              <Link
                className="btn edit-btn"
                  to={`/dashboard/notifications/${data?.id}/edit`}
              >
                Edit
              </Link>

              <button
                className="btn delete-btn"
                onClick={() => {
                  setDeleteBtnClicked(true);
                  setConfirmShow(true);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </td>
      </tr>

      {confirmShow ? (
        <ConfirmAlert
          show={confirmShow}
          onHide={() => {
            setConfirmShow(false);
          }}
          title={
            deleteBtnClicked
              ? "Are you sure you want to delete this Notfication?"
              : status === 0
              ? "Are you sure to activate Notfication?"
              : "Are you sure to deactivate Notfication?"
          }
          confirmButtonName={"Yes"}
          cancelButtonName={"No"}
          onButtonClick={() => {
            deleteBtnClicked ? deleteRecord() : updateUserStatus();
          }}
        />
      ) : null}
    </>
  );
}

export default TableData;

import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import Inputfield from "../../components/FormFields/InputField";
import {getSettings, saveSettngs} from "../../actions/Users";

function GeneralSettings() {
  // use hooks
  const {control, handleSubmit, reset, setValue, formState: { isSubmitting }, } = useForm();

  // on form subit
  const onSubmit = async(formData) => {
    // console.log(formData);
    try {
      await saveSettngs(formData);
      toast.success("Settings has been updated successfully.");
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  };

  const getSetting = async () => {
    try {
      const resp = await getSettings();
      setValue("adminEmail", resp?.data?.responseData?.adminEmail);
      setValue("welcomeMessage", resp?.data?.responseData?.welcomeMessage);
      console.log('sssssss', resp);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  };

  // usehooks
  useEffect(() => {
    getSetting();
  });

  console.log('ewewewewewwwwwwwww');  

  return (
    <>
      <div className="container ani-ui-block shop-manager">
        <div className="row menu-top-block">
          <div className="col-sm-12 tl-block align-self-center">
            <h4 className="heading">Admin settings</h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              {/* {detail === null && <div className="content-loader"></div>} */}

              <div className="col-lg-12 col-md-12">
                <div className="add-left-block">
                  <div className="fields-ui-block">
                    <div className="basic-details">
                      <div className="heading">
                        <h4>Details</h4>
                      </div>
                      <div className="form-block">
                        <div className="row">
                          <div className="col-lg-6">
                            <Inputfield
                              control={control}
                              name={"adminEmail"}
                              label="Admin email"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              placeholder="Admin email"
                              type="email"
                              inputClassName={"textfield"}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Email is required",
                                },
                                pattern: {
                                  value:
                                    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                                  message: "Please enter valid email address",
                                },
                              }}
                            />
                          </div>

                          <div className="col-lg-6">
                            <Inputfield
                              control={control}
                              name={"welcomeMessage"}
                              label={"Welcome message"}
                              placeholder="Welcome message"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              type="text"
                              inputClassName={"textfield"}
                            />
                          </div>

                          
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row save-button-block">
                    <div className="col-sm-12 align-self-center">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`btn orange-btn ${
                          isSubmitting ? "btn-loader" : ''
                        }`}
                      >
                        Submit details
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default GeneralSettings;

import axios from "axios";

export function listGroupOrCategory(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/cms/group'
    })
}

export function addGroup(payload){
    return axios({
        method: 'POST',
        data: payload,
        url: '/cms/admin/group'
    })
}

export function listGroupCategory(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/cms/group'
    })
}

export function addGroupCategory(payload){
    return axios({
        method: 'POST',
        data: payload,
        url: '/cms/group'
    })
}

export function editGroupCategory(payload){
    return axios({
        method: 'PATCH',
        data: payload,
        url: '/cms/group'
    })
}

export function listBlogs(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/post'
    })
}

export function addBlogs(payload){
    return axios({
        method: 'POST',
        data: payload,
        url: '/post'
    })
}

export function editBlogs(payload){
    return axios({
        method: 'PATCH',
        data: payload,
        url: '/post/admin'
    })
}

export function deleteBlog(data) {
    return axios({
      method: "DELETE",
      params: data,
      url: `/post`,
    });
  }

  export function getBlogById(data) {
    return axios({
      method: "GET",
      params: data,
      url: `/post/id`,
    });
  }
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { dashboardStats } from "../../actions/dashboard";
import LineGraphComponent from "../../components/Common/lineGraphComponent";
import ReactSelectField from "../../components/FormFields/ReactSelectField";
import { getDates, isAuthenticated } from "../../utilities";
import Loader from "../../components/Common/loader";

function Dashboard() {
  // use hooks
  const navigate = useNavigate();

  //  local variables
  const [dashboardData, setDashboardData] = useState(null);
  const [currentDayType, setCurrentDayType] = useState("LAST_7_DAYS");
  const [isLoading, setIsLoading] = useState(false);

  let defaultValues = {
    record: "appointment",
    clinic: "",
    time: "LAST_7_DAYS",
  };


  // use hooks
  const { control, setValue } = useForm({ defaultValues: defaultValues });

  const fetchData = useCallback(async (currentDayType) => {
    // setIsLoading(true);
    try {
      const resp = await dashboardStats({...getDates(currentDayType), option: currentDayType == 'LAST_YEAR' ? 3 : 1});
      setDashboardData(resp?.data?.responseData);
      setIsLoading(false);
    } catch ({ response, request }) {
      setIsLoading(false);
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  }, []);


  useEffect(() => {
    fetchData(currentDayType);
  }, [currentDayType]);

  // use hooks
  useEffect(() => {
    if (!isAuthenticated(localStorage.getItem("jwtToken"))) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <div className="dash-inner-container">
        <div className="row menu-top-block">
          <div className="col-sm-5 tl-block align-self-center">
            <h4 className="heading">Dashboard</h4>
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="dash-main-container">
            <div className="insight-wrpr">
              <h6 className="text-dark dash-title">Insights</h6>
              {/* justify-content-between */}
              <div className="d-flex align-items-center  insights-card-wrpr">
                <div className="insight-card-block">
                  <div className="d-flex align-items-center insight-card today">
                    <div className="insight-card-img">
                      <span className="i-card-img today"></span>
                    </div>
                    <div className="insight-card-content">
                      <h3 className="text-dark insight-title">
                        {dashboardData?.todayCount}
                      </h3>
                      <p className="insight-desc">Today’s appointments</p>
                    </div>
                  </div>
                </div>
                <div className="insight-card-block">
                  <div className="d-flex align-items-center insight-card all-appointments">
                    <div className="insight-card-img">
                      <span className="i-card-img all-appointments"></span>
                    </div>
                    <div className="insight-card-content">
                      <h3 className="text-dark insight-title">
                        {dashboardData?.totalAppointments}
                      </h3>
                      <p className="insight-desc">Total Appointments</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white patient-graph-wrpr">
              <div className="d-flex align-items-center justify-content-between upcoming-appt-head">
                <h4 className="text-dark dash-title">Shared Records</h4>
                <div className="d-flex align-center graph-select">
                  <div className="dropdown">
                    <ReactSelectField
                      control={control}
                      label=""
                      name={`record`}
                      placeholder=""
                      containerClass="dash-record"
                      options={[
                        {
                          name: "Appointments",
                          value: "appointment",
                        },
                      ]}
                      optionValue="value"
                      optionLabel="name"
                      rules={{
                        required: { value: false },
                      }}
                    />
                  </div>
                  <div className="dropdown">
                    <ReactSelectField
                      control={control}
                      label=""
                      name={`time`}
                      placeholder=""
                      containerClass="dash-record"
                      options={[
                        // {
                        //   name: "Day",
                        //   value: "TODAY",
                        // },
                        {
                          name: "Week",
                          value: "LAST_7_DAYS",
                        },
                        {
                          name: "Month",
                          value: "LAST_30_DAYS",
                        },
                        {
                          name: "Year",
                          value: "LAST_YEAR",
                        },
                      ]}
                      optionValue="value"
                      optionLabel="name"
                      onSelect={(_e) => {
                        setCurrentDayType(_e.value);
                      }}
                      rules={{
                        required: { value: false },
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="graph-blk">
                <LineGraphComponent dashboardData={dashboardData} currentDayType={currentDayType} />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Dashboard;

import { Link } from "react-router-dom";
// import moment from "moment";
import { useEffect, useState } from "react";
// import { toast } from "react-hot-toast";
// import ToolTip from "../../../components/Common/ToolTip";
// import ConfirmAlert from "../../../components/Common/Modals/ConfirmAlert";
// import { BASE_URL } from "../../../constants";

function DiagnosisTestSlideData({  data, index, srno, reload }) {
    //   local variables
    
  return (
    <>
      <tr className="modal-tr">
        <td>{parseInt(srno + parseInt(index + 1))}</td>
        <td>{data?.venomCode}</td>
        <td>{data?.name}</td>
        <td>
          <div className="table-btn-block">
            <div className="table-btn-block">
              <Link
                className="btn edit-btn"
                  to={`/dashboard/diagnosis-test/${data?.id}/edit`}
              >
                Edit
              </Link>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
}

export default DiagnosisTestSlideData;

import LoginForm from "./LoginForm";

function Login() {

  return (
    <>
      <div style={{ height: "100%" }}>
        <div className="login-page">
          <div className="login-logo">
          <img src="/assets/images/logo.png" alt="Kinn" />
          </div>
          <div className="login-head">
            <h2>
              <span>Admin Portal</span>
            </h2>
            <p>Please fill the details to continue</p>
          </div>
          <LoginForm />
        </div>
      </div>
    </>
  );
}

export default Login;

import { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import CSVReader from "react-csv-reader";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { uploadCsvVenomSpices } from "../../../actions/animal";
import csvTemplate from '../../../csv/species-csv-template.csv'
import otherCsvTemplate from '../../../csv/sample_file.csv';
function UploadCsvSpices() {
  // use hooks
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm();

  const navigate = useNavigate();
  const { type } = useParams();

  const [records, setRecords] = useState(null);
  let [fileType, setFileType] = useState('Add species & venom');
  let [message, setMessage] = useState('Select CSV with Species & their Venoms');
  let [nav, setNav] = useState(null);
  let [sampleFile, setSampleFile] = useState(csvTemplate);


  const handleForce = (data, fileInfo) => {
    setRecords(data);
  }
  const { animal } = useParams();
  
  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header => header.replace(/\W/g, "_")
    // transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
  };

  // on form submissions
  const onSubmit = async (data) => {
    try {
      if(records == null && (Object.keys(data).length === 0)){
        toast("Please select CSV file.");
      } else {
        let result = await uploadCsvVenomSpices({...data, csvRecords: records, type});
        navigate(nav);
        // if(type == 1){
        //   navigate("/dashboard/species");
        // } else if(type == 2){
        //   navigate("/dashboard/diagnosis");
        // } else if(type == 3){
        //   navigate("/dashboard/diagnosis-test");
        // } else if(type == 4){
        //   navigate("/dashboard/visit-reason");
        // } else if(type == 5){
        //   navigate("/dashboard/presenting-complain");
        // }
        toast.success("Records has been updated successfully.");
      }
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  };

  useEffect(() => {
    if(type == 1){
      setFileType('Add species & venom');
      setMessage('Select CSV with Species & their Venoms');
      setNav("/dashboard/species");
    } else if(type == 2){
      setSampleFile(otherCsvTemplate)
      setFileType('Add diagnosis & venom');
      setMessage('Select CSV with diagnosis & their Venoms');
      setNav("/dashboard/diagnosis");
    } else if(type == 3){
      setSampleFile(otherCsvTemplate)
      setFileType('Add diagnosis test & venom');
      setMessage('Select CSV with diagnosis test & their Venoms');
      setNav("/dashboard/diagnosis-test");
    } else if(type == 4){
      setSampleFile(otherCsvTemplate)
      setFileType('Add visit reason & venom');
      setMessage('Select CSV with visit reason & their Venoms');
      setNav("/dashboard/visit-reason");
    } else if(type == 5){
      setSampleFile(otherCsvTemplate)
      setFileType('Add presenting complain & venom');
      setMessage('Select CSV with presenting complain & their Venoms');
      setNav("/dashboard/presenting-complain");
    }
  }, []);

return (
    <>
      <div className="container ani-ui-block shop-manager">
        <div className="row menu-top-block">
          <div className="col-sm-12 tl-block align-self-center">
            <h4 className="heading">
              {fileType}
            </h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="add-left-block">
                  <div className="fields-ui-block">
                    <div className="basic-details">
                      <div className="heading">
                        <h4>Upload CSV</h4>
                      </div>
                      
                      <div className="form-block">
                      <label className="download-label-style">Download sample file <a href={sampleFile} target="_blank">Click here</a></label>
                        <div className="row">
                          <CSVReader
                            cssClass="react-csv-input"
                            label={message}
                            onFileLoaded={handleForce}
                            parserOptions={papaparseOptions}
                          />
                          
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row save-button-block">
                    <div className="col-sm-12 align-self-center">
                      <div className="btn-wrpr">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className={`btn-full orange-btn ${
                            isSubmitting && "btn-loader"
                          }`}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default UploadCsvSpices;

import { Fragment, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

import { getAllRoles, editStaff, getStaffWithId } from "../../../actions/acl";
import Inputfield from "../../../components/FormFields/InputField";
import InputField from "../../../components/FormFields/InputField";
import ReactSelectField from "../../../components/FormFields/ReactSelectField";

function EditStaff() {
  // ------------------- Use hooks -----------------------
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm();

  const navigate = useNavigate();
  const { id } = useParams();

  // ------------------- Global states ---------------------
  const [roles, setRoles] = useState([]);

  const onSubmit = async (data) => {
    try {
      await editStaff({"id": id, 'name': data.name, 'email': data.email, 'role': data.role});

      toast.success("Staff added successfully");
      navigate("/dashboard/staff");
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const fetchRecords = useCallback(async () => {
    try {
      const resp = await getStaffWithId({ id: id });

      let roles = [];

      resp?.data?.responseData?.Roles.length > 0 && resp?.data?.responseData?.Roles.map((obk, index) => {
        roles.push(obk.id);
      })

      setValue("name", resp?.data?.responseData?.UserProfile?.name);
      setValue("email", resp?.data?.responseData?.email);
      setValue('role', roles);
      
      console.log('4444', resp);

    } catch (error) {
      console.error(error);
    }
  }, [id]);

  const fetchRoles = useCallback(async () => {
    try {
      const resp = await getAllRoles();
      setRoles(resp?.data?.responseData?.roles);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchRoles();
    fetchRecords();
  }, [fetchRoles]);

  return (
    <>
      <div className="container ani-ui-block shop-manager">
        <div className="row menu-top-block">
          <div className="col-sm-12 tl-block align-self-center">
            <h4 className="heading">Edit staff</h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="add-left-block">
                  <div className="fields-ui-block">
                    <div className="basic-details">
                      <div className="heading">
                        <h4>Basic Details</h4>
                      </div>
                      <div className="form-block">
                        <div className="row">
                            <div className="col-6">
                              <InputField
                                control={control}
                                label="Name"
                                name="name"
                                type="text"
                                placeholder={'Name'}
                                normalize={(e) => e.replace(/^\s+/g, "")}
                                fieldClass="form-control"
                                rules={{
                                  required: {
                                    value: true,
                                    message: "Name is required",
                                  },
                                }}
                              />
                            </div>

                            <div className="col-6">
                              <Inputfield
                                control={control}
                                name={"email"}
                                label="Email address"
                                placeholder="Email address"
                                normalize={(e) => e.replace(/^\s+/g, "")}
                                type="email"
                                inputClassName={"textfield"}
                                rules={{
                                  required: {
                                    value: true,
                                    message: "Email is required",
                                  },
                                  pattern: {
                                    value:
                                      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                                    message: "Please enter valid email address",
                                  },
                                }}
                              />
                            </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-12">
                            <ReactSelectField
                              control={control}
                              name="role"
                              label={"Select Role"}
                              placeholder="Select Role"
                              options={roles}
                              optionValue="id"
                              optionLabel="name"
                              multi="true"
                              normalize={(_value) => _value?.value}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Role is required",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row save-button-block">
                    <div className="col-sm-12 align-self-center">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`btn orange-btn ${
                          isSubmitting ? "btn-loader" : ""
                        }`}
                      >
                        Submit details
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default EditStaff;

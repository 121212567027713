import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

import { SpeciesAdd } from "../../../../actions/animal";
import Inputfield from "../../../../components/FormFields/InputField";
import ImageUploaderField from "../../../../components/FormFields/ImageUploaderField";

function AddSpices() {
  // use hooks
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm();

  const navigate = useNavigate();

  // on form submissions
  const onSubmit = async (formData) => {
    try {
      await SpeciesAdd({ ...formData });
      navigate("/dashboard/species");
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  };

  return (
    <>
      <div className="container ani-ui-block shop-manager">
        <div className="row menu-top-block">
          <div className="col-sm-12 tl-block align-self-center">
            <h4 className="heading">Add Species</h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="add-left-block">
                  <div className="fields-ui-block">
                    <div className="basic-details">
                      <div className="heading">
                        <h4>Basic Details</h4>
                      </div>
                      <div className="form-block">
                        <div className="row">
                          <div className="col-lg-6">
                            <ImageUploaderField
                              control={control}
                              label="Add Image*"
                              name="attachment"
                              uploadType="image"
                              setValue={setValue}
                              changeLabel={'image'}
                              uploadText="Upload image"
                              extensionTypes={["jpeg", "jpg", "png"]}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Image is required",
                                },
                              }}
                            />
                          </div>

                          <div className="col-lg-6">
                            <Inputfield
                              control={control}
                              label="Name*"
                              name="name"
                              type="text"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Name is required",
                                },
                                maxLength: {
                                  value: 40,
                                  message: "Maximum 40 characters are allowed!",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row save-button-block">
                    <div className="col-sm-12 align-self-center">
                      <div className="btn-wrpr">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className={`btn-full orange-btn ${
                            isSubmitting && "btn-loader"
                          }`}
                        >
                          Submit details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddSpices;

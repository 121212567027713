import { Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import "./App.css";
import Login from "./pages/Login/Login";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import Layout from "./pages/Layout/Layout";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import { ProtectedLoginRoute } from "./pages/Private route/ProtectedLoginRoute";


function App() {
  
  return (
    <>
      <Routes>
      <Route path="/" element={<ProtectedLoginRoute element={<Login />} />} />
        <Route path="login" element={<ProtectedLoginRoute element={<Login />} />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="dashboard/*" element={<Layout />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>

      <Toaster position="top-right" />
    </>
  );
}

export default App;

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Inputfield from "../../components/FormFields/InputField";
import ReactDatePicker from "../../components/FormFields/ReactDatePicker";
import ReactSelectField from "../../components/FormFields/ReactSelectField";
import { removeEmptyFields } from "../../utilities";

function PaymentHistoryFilters({ getFilterFields }) {
  // use hooks
  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm();

  const navigate = useNavigate();
  const statusTypes = [
    {
      id: 1,
      label: "In Progress",
    },
    {
      id: 2,
      label: "Pending",
    },
    {
      id: 3,
      label: "Completed",
    },
  ];

  //   } = useForm({ defaultValues: queryString.parse(search) });

  const onSubmit = (data) => {
    const values = removeEmptyFields(data);
    const queryParams = new URLSearchParams(values);
    navigate({
      search: queryParams.toString(),
    });
    getFilterFields(values);
  };

  const resetFilter = () => {
    navigate({
      search: "",
    });
    getFilterFields({});
    reset({ date: "", status: "" });
  };

  return (
    <>
      <form name="filter" onSubmit={handleSubmit(onSubmit)}>
        <div className="row filter-block">
          <div className="col-sm-12 col-md-12 align-self-center">
            <div className="row align-items-end">
              <div className="col-lg-3">
                <ReactDatePicker
                  control={control}
                  label="Date"
                  placeholder=""
                  name="date"
                  maxDate={new Date()}
                />
              </div>

              <div className="col-lg-3">
                <ReactSelectField
                  control={control}
                  name="status"
                  placeholder="Select Status"
                  options={statusTypes}
                  optionValue="id"
                  optionLabel="label"
                  normalize={(value) => value?.id}
                />
              </div>

              <div className="col-lg-3">
                <div className="row">
                  <div className="col-6 align-self-center">
                    <div className="filter-result-block form-group">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`btn btn1 btn-center ${
                          isSubmitting ? "btn-loader" : ''
                        }`}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                  <div className="col-6 align-self-center">
                    <div className="filter-result-block form-group">
                      <button
                        type="button"
                        onClick={resetFilter}
                        className="btn btn1 btn-center"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default PaymentHistoryFilters;

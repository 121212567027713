import { useEffect } from "react";
import { useController } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { errorType } from "../../constants";

function ReactPhoneInput({
  phoneNumber: { phoneName, phoneRules, phoneDefaultValue },
  countryCode: { countryCodeName, countryCodeRules, defaultValue },
  countryCodeValue: { countryCodeValue, defaultCountryCodeValue },
  control,
  label,
  errorClass,
  defaultCountry,
  inputClass,
  onChange,
}) {
  // use form hooks
  const {
    field: phoneNumberField,
    fieldState: { error: phoneNumberError },
  } = useController({
    name: phoneName,
    control,
    rules: phoneRules,
    defaultValue: phoneDefaultValue,
  });

  const {
    field: countryCodeField,
    fieldState: { error: countryCodeError },
  } = useController({
    name: countryCodeName,
    control,
    rules: countryCodeRules,
    defaultValue: defaultValue,
  });

  const {
    field: countryCodeValueField,
    fieldState: { error: countryCodeValueError },
  } = useController({
    name: countryCodeValue,
    control,
    rules: {},
    defaultValue: defaultCountryCodeValue,
  });




  //   on handling value
  const onHandleValue = () => {
    if (phoneNumberField?.value) {
      return `${countryCodeField?.value}${phoneNumberField.value}`;
    } else {
      return "65";
    }
  };

  // on input change
  const handleOnChange = (value, country) => {
    if (onChange)
      onChange(country?.dialCode, value.slice(country.dialCode.length), country?.countryCode);

    // send value to hook form
    countryCodeValueField.onChange(country?.countryCode);
    countryCodeField.onChange(country?.dialCode);
    phoneNumberField.onChange(value.slice(country.dialCode.length));
  };

  useEffect(() => {
    countryCodeValueField.onChange(defaultCountryCodeValue);
    phoneNumberField.onChange(phoneDefaultValue);
    countryCodeField.onChange(defaultValue);
  }, []);

  return (
    <>
      <div className="form-group">
        {label && <label>{label}</label>}
        <div className="textfield-block">
          <PhoneInput
            country={defaultCountry}
            value={onHandleValue()}
            inputClass={inputClass}
            // disabled={true}
            onChange={(value, country) => handleOnChange(value, country)}
          />

          {errorType?.map((type) => {
            if (
              phoneNumberError?.type === type &&
              phoneNumberError?.message !== ""
            ) {
              return (
                <span key={type} className={errorClass || "error-msg"}>
                  {phoneNumberError?.message}
                </span>
              );
            }
          })}
        </div>
      </div>
    </>
  );
}

export default ReactPhoneInput;

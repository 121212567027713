import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-hot-toast';
import Inputfield from "../../../components/FormFields/InputField";
import JoditEdior from "../../../components/FormFields/JoditEditor"
import { getMedicationById, editMedication } from "../../../actions/animal";

function EditMedication() {
  // use hooks
  const {
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const { id } = useParams();
  const navigate = useNavigate();

  // on form submissions
  const onSubmit = async(formData) => {
    try {
      await editMedication({...formData, status: 1});
      navigate(-1);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  };

  const getMedicineById = async() => {
    try{
      const resp = await getMedicationById({id});
      setValue('name', resp?.data?.responseData?.name ?? null);
      setValue('code', resp?.data?.responseData?.code ?? null);
      setValue('category', resp?.data?.responseData?.category ?? null);
      setValue('brand', resp?.data?.responseData?.brand ?? null);
      setValue('status', resp?.data?.responseData?.status ?? null);
      setValue('sellingPrice', resp?.data?.responseData?.sellingPrice ?? null);
      setValue('supplier', resp?.data?.responseData?.supplier ?? null);
      setValue('displayUom', resp?.data?.responseData?.displayUom ?? null);
      setValue('presentUom', resp?.data?.responseData?.presentUom ?? null);
      setValue('totalUom', resp?.data?.responseData?.totalUom ?? null);
      setValue('sdd', resp?.data?.responseData?.sdd ?? null);
      setValue('drugRemarks', resp?.data?.responseData?.drugRemarks ?? null);
      setValue('nir', resp?.data?.responseData?.nir ?? null);
      setValue('defaultDose', resp?.data?.responseData?.defaultDose ?? null);
      setValue('defaultFrequency', resp?.data?.responseData?.defaultFrequency ?? null);
      setValue('defaultDays', resp?.data?.responseData?.defaultDays ?? null);
      setValue('meals', resp?.data?.responseData?.meals ?? null);
      setValue('quantity', resp?.data?.responseData?.quantity ?? null);
      setValue('instructions', resp?.data?.responseData?.instructions ?? null);
      setValue('precautions', resp?.data?.responseData?.precautions ?? null);
      setValue('id', resp?.data?.responseData?.id ?? null);
      setValue('type', resp?.data?.responseData?.type ?? null);
    }catch({response, request}){
      if(response){
        toast.error(response?.data?.message)
      }else if(request){
        toast.error('You do not have internet connection');
      }
    }
  }

  // usehooks
  useEffect(()=> {
    if(id){
      getMedicineById(id);
    }
  },[id])

  return (
    <>
      <div className="container ani-ui-block shop-manager">
        <div className="row menu-top-block">
          <div className="col-sm-12 tl-block align-self-center">
            <h4 className="heading">Edit Medication</h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              
              <div className="col-lg-12 col-md-12">
                <div className="add-left-block">
                  <div className="fields-ui-block">
                    <div className="basic-details">
                      <div className="heading">
                        <h4>Basic Details</h4>
                      </div>
                      <div className="form-block">
                        <div className="row">
                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              label="Name*"
                              name="name"
                              type="text"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Name is required",
                                },
                              }}
                            />
                          </div>
                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              label="Code*"
                              name="code"
                              type="text"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Code is required",
                                },
                              }}
                            />
                          </div>
                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              label="Category*"
                              name="category"
                              type="text"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Category is required",
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              label="Type*"
                              name="type"
                              type="text"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Type is required",
                                },
                              }}
                            />
                          </div>
                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              label="Brand*"
                              name="brand"
                              type="text"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Brand is required",
                                },
                              }}
                            />
                          </div>
                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              label="Selling Price*"
                              name="sellingPrice"
                              type="text"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Selling price is required",
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              label="Supplier*"
                              name="supplier"
                              type="text"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Supplier is required",
                                },
                              }}
                            />
                          </div>
                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              label="Display Uom*"
                              name="displayUom"
                              type="text"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Display Uom is required",
                                },
                              }}
                            />
                          </div>
                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              label="Present Uom*"
                              name="presentUom"
                              type="text"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Present Uom is required",
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              label="Total Uom*"
                              name="totalUom"
                              type="text"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Total Uom is required",
                                },
                              }}
                            />
                          </div>
                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              label="Sdd*"
                              name="sdd"
                              type="text"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Sdd is required",
                                },
                              }}
                            />
                          </div>
                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              label="Drug Remarks*"
                              name="drugRemarks"
                              type="text"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Drug Remarks is required",
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              label="Nir*"
                              name="nir"
                              type="text"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Nir is required",
                                },
                              }}
                            />
                          </div>
                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              label="Default Dose*"
                              name="defaultDose"
                              type="number"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Default Dose is required",
                                },
                              }}
                            />
                          </div>
                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              label="Default Frequency*"
                              name="defaultFrequency"
                              type="text"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Default Frequency is required",
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              label="Default Days*"
                              name="defaultDays"
                              type="number"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Default Days is required",
                                },
                              }}
                            />
                          </div>
                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              label="Meals*"
                              name="meals"
                              type="text"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Meals is required",
                                },
                              }}
                            />
                          </div>
                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              label="Quantity*"
                              name="quantity"
                              type="text"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Quantity is required",
                                },
                              }}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <JoditEdior
                              control={control}
                              name="instructions"
                              label="Instructions*"
                              errorClass="error-msg"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Instructions is required",
                                },
                              }}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <JoditEdior
                              control={control}
                              name="precautions"
                              label="Precautions*"
                              errorClass="error-msg"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Precautions is required",
                                },
                              }}
                            />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="row save-button-block">
                    <div className="col-sm-12 align-self-center">
                      <div className="btn-wrpr">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className={`btn-full orange-btn ${
                            isSubmitting && "btn-loader"
                          }`}
                        >
                          Submit details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default EditMedication;

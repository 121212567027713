import { useCallback, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useParams, Link } from "react-router-dom";
import queryString from "query-string";
import Pagination from "react-js-pagination";

import { limit } from "../../constants";
import TransactionSlide from "./TransactionSlide";
import TransactionFilter from "./TransactionFilter";
import { getTransactionList } from "../../actions/Users";

function Transactions() {
  // use hooks
  const { search } = useLocation();

  const { status } = useParams();
  // const location = useLocation();

  // local variables
  const [list, setList] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [filters, setFilters] = useState(queryString.parse(search));

  const fetchData = useCallback( async (page, filters, status) => {
    try {
      const resp = await getTransactionList({ ...filters, pageNumber: page });
      setList(resp?.data?.responseData);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  },[]);

  const handlePageChange = (page) => {
    setActivePage(page);
  };

  const getFilterFields = (filters) => {
    setActivePage(1);
    setFilters(filters);
  };

  useEffect(() => {
    fetchData(activePage, filters);
  }, [fetchData, activePage, filters]);

  return (
    <>
      <div className="right-ui-block">
        <div className="scrollspy-example">
          <div className="rm-content">
            <div className="row menu-top-block">
              <div className="col-sm-5 tl-block align-self-center">
                <h4 className="heading">
                  Transactions ({list?.totalRecords ? list.totalRecords : 0})
                </h4>
              </div>
            </div>
            <TransactionFilter getFilterFields={getFilterFields} />
            <div className="row">
              <div className="col-sm-12">
                <div className="result-listing">
                  <div className="table-responsive">
                    <table>
                      <thead>
                        <tr>
                          <th>Sr.no.</th>
                          <th>User Name</th>
                          <th className="manage-content blogtitle">Plan</th>
                          <th className="">Amount</th>
                          <th className="">Start Date</th>
                          <th className="">End Date</th>
                          <th className="">Email</th>
                          <th className="">Contact Number</th>
                          <th className="">Invoice</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list === null ? (
                          <tr>
                            <td colSpan={10} align="center">
                              <div className="table-loader" />
                            </td>
                          </tr>
                        ) : list?.logs?.length > 0 ? (
                          list?.logs?.map?.((obj, index) => (
                            <TransactionSlide
                              key={obj.id}
                              data={obj}
                              index={index}
                              srno={(activePage - 1) * limit}
                              reload={() => fetchData(activePage, filters)}
                            />
                          ))
                        ) : (
                          <tr>
                            <td
                              className="no-record"
                              colSpan={10}
                              align="center"
                            >
                              No record found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {list?.logs?.length > 0 && (
                    <div className="pagination">
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={
                            list?.perPage ? list?.perPage : limit
                        }
                        totalItemsCount={
                            list?.totalRecords ? list?.totalRecords : 0
                        }
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Transactions;

import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";

import InputTextAreaField from "../../components/FormFields/InputTextAreaField";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Inputfield from "../../components/FormFields/InputField";
import { FEATURE_DETAIL, GetPlans, GetFeatureById,EditFeatureById } from "../../actions/plan";
import MultiSelectCheckboxField from "../../components/FormFields/MultiSelectCheckboxField";

function EditFeature() {

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
      planPrice: [
        {
          currencyId: "",
          amount: "",
        },
      ],
    },
  });
  const dispatch = useDispatch();
  const { id } = useParams();
  const [plans, setPlans] = useState([]);
  // const plans = [{value: 1, name: "Early Birds"}, {value: 2, name: "Freemium"}, {value: 3, name: "Free Trial"}]
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "planPrice", // unique name for your Field Array
  });
  let [list, setList] = useState([])
  const navigate = useNavigate();
  const fetchPlan = async () => {
    try {
      const resp = await GetPlans({ status: 1});
      let tempPlanAry = resp?.data?.responseData.plans.map((plan) => ({
        value: plan.mainContent.planId,
        name: plan.mainContent.name
      }))
      setPlans(tempPlanAry);
      console.log("this is plan list", resp?.data?.responseData)
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  };
  const getFeatureById = async() => {
  try {
      let resp = await GetFeatureById({ id });
      console.log("this is resp", resp)
      let records = resp?.data?.responseData;

      reset(records.mainContent[0]);
      // setFeatures(records.features)
      console.log("this is featrues EDIT", records.mainContent)
      dispatch({ type: FEATURE_DETAIL, payload: records });
      // let tempPlanAry = records.Plans.map((plan) => ({
      //   value: plan.mainContent[0].planId,
      //   name: plan.mainContent[0].name
      // }))
      setValue("plans", records?.Plans?.map(e=>e.id), {
        shouldValidate: true,
      })
      // console.log('this is temp plan ary', tempPlanAry)
      // setPlans(tempPlanAry)
      // setValue("name", records?.name);
      // setValue("duration", records?.duration);
      // setValue("description", records?.description);
      // setValue("planPrice", records?.planPrice);

    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  }
  // on form submissions
  const onSubmit = async (formData) => {
    console.log(formData, "formData");
    try {
      let requestData = {id: formData.featureId, name: formData.name, plans: formData.plans, description: formData.description}
      await EditFeatureById(requestData)
      navigate(-1);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  };

  const onAddAmount = () => {
    append({ currencyId: "", amount: "" });
  };

  const onDeleteAmount = (index) => {
    remove(index);
  };

  useEffect(() => {
    getFeatureById()
    fetchPlan()
  }, []);

  return (
    <>
      <div className="container ani-ui-block shop-manager">
        <div className="row menu-top-block">
          <div className="col-sm-12 tl-block align-self-center">
            <h4 className="heading">Edit Feature</h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="add-left-block">
                  <div className="fields-ui-block">
                    <div className="basic-details">
                      <div className="heading">
                        <h4>Basic Details</h4>
                      </div>
                      <div className="form-block">
                        <div className="row">
                          <div className="col-lg-6">
                            <Inputfield
                              control={control}
                              label="Name*"
                              name="name"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              type="text"
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Name is required",
                                },
                                maxLength: {
                                  value: 40,
                                  message: "Maximum 40 characters are allowed!",
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <InputTextAreaField
                              control={control}
                              name="description"
                              label={"Description"}
                              placeholder="Description"
                              type="text"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Description is required",
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <MultiSelectCheckboxField
                              control={control}
                              name="plans"
                              label={"Plans"}
                              placeholder="Plans"
                              options={plans}
                              optionValue="value"
                              optionLabel="name"
                              normalize={(_value) => _value?.value}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Plans is required",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row save-button-block">
                    <div className="col-sm-12 align-self-center">
                      <div className="btn-wrpr">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className={`btn-full orange-btn ${
                            isSubmitting && "btn-loader"
                          }`}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default EditFeature;

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import ConfirmAlert from "../../../components/Common/Modals/ConfirmAlert";
import { BASE_URL } from "../../../constants";

function DetailSlide({ index, slideData, vetId}) {
  const [confirmShow, setConfirmShow] = useState(false);
  //local variables
  const deleteRecord = async () => {
    try {
      // await deleteUser({ userId: data?.id });
      toast.success("Clinic has been deleted successfully");
    } catch (error) {
      toast.error(`${error?.response?.data?.message}`);
    }
  };

  return (
    <>
     <tr className="modal-tr">
        <td>{ parseInt(index + 1) }</td>
        <td>{slideData?.profile?.path ? <img className="img-fluid table-img" src={`${BASE_URL}${slideData?.profile?.path}`} width="70" /> : null}</td>
        <td>{slideData?.name}</td>
        <td>{slideData?.countryCode} {slideData?.phone}</td>
        <td>{slideData?.address}</td>
        <td><Link to={`/dashboard/vets/${vetId}/${slideData?.id}/staff`}>Manage</Link></td>
       <td>
        {
          slideData?.licence?.path ? <a href={`${BASE_URL}${slideData?.licence?.path}`} target="_blank">Licence</a> : null }</td>
       <td>{slideData?.signature?.path ? <a href={`${BASE_URL}${slideData?.signature?.path}`} target="_blank">Signature</a> : null }</td>
      </tr>

      {
        confirmShow ? (
        <ConfirmAlert
          show={confirmShow}
          onHide={() => {
            setConfirmShow(false);
          }}
          title="Are you sure you want to delete this Clinic?"
          confirmButtonName={"Yes"}
          cancelButtonName={"No"}
          onButtonClick={() => deleteRecord() }
        />
      ) : null}
    </>
      
  );
}

export default DetailSlide;

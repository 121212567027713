import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import Inputfield from "../../../components/FormFields/InputField";
import JoditEdior from "../../../components/FormFields/JoditEditor";
import ImageUploaderField from "../../../components/FormFields/ImageUploaderField";
import { addBlogs, listGroupOrCategory } from "../../../actions/cms";
import ReactSelectField from "../../../components/FormFields/ReactSelectField";
import { useEffect, useState } from "react";
import InputTagField from "../../../components/FormFields/InputTagField";
import UploadVideoField from "../../../components/FormFields/UploadVideoField";
import InputTextAreaField from "../../../components/FormFields/InputTextAreaField";
import { VIEWER_TYPE } from "../../../constants";
import MultiImageUploaderField from "../../../components/FormFields/MultiImageUploaderField";

function AddBlog() {
  // use hooks
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm();

  // use hooks
  const navigate = useNavigate();

  // local variables
  const [allCategories, setAllCategories] = useState(null);
  // const [allSubCategories, setAllSubCategories] = useState(null);
  const [status, setStatus] = useState(null);
  const [images, setAllImages] = useState(null);
  const [isLoadingPublishBtn, setIsLoadingPublishBtn] = useState(0);

  // on form submissions
  const onSubmit = async (formData) => {
    if(status == 0) setIsLoadingPublishBtn(1)
    else setIsLoadingPublishBtn(2)
    if (
      formData?.attachmentVideo &&
      Object.keys(formData?.attachmentVideo)?.length > 0
    ) {
      formData.attachmentVideo = [formData?.attachmentVideo];
    } else {
      delete formData?.attachmentVideo;
    }

    if (images?.length > 0 || formData?.attachment == "") {
      formData.attachment = images;
    } else {
      delete formData?.attachment;
    }
    try {
      await addBlogs({ ...formData, status });

      navigate(`/dashboard/blogs/${status ? status : 0}`)
      setIsLoadingPublishBtn(0);
    } catch ({ response, request }) {
      setIsLoadingPublishBtn(0);
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  };

  // const fetchSubCatData =  async (id) => {
  //   try {
  //     const resp = await listGroupOrCategory({ groupParentId: id, groupType: 'category'});
  //     setAllSubCategories(resp?.data?.responseData?.groups);
  //   } catch ({ response, request }) {
  //     if (response) {
  //       toast.error(response?.data?.message);
  //     } else if (request) {
  //       toast.error("You do not have internet connection");
  //     }
  //   }
  // };

  const fetchData = async () => {
    try {
      const resp = await listGroupOrCategory({
        groupType: "category",
      });

      let array = [];
      if (resp?.data?.responseData?.groups?.length > 0) {
        resp?.data?.responseData?.groups?.map((_group) => {
          _group.groupName = _group?.defaultContent?.[0]?.groupName;
          array.push(_group);
        });
      }
      setAllCategories(array);
      // if(resp?.data?.responseData?.groups?.length>0){
      //   setValue('groupId', resp?.data?.responseData?.groups?.[0]?.id);
      //   fetchSubCatData(resp?.data?.responseData?.groups?.[0]?.id);
      // }
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  };

  // const onSelectCategory = (_id) => {
  //   if(_id){
  //     setValue('groupId', '')
  //     fetchSubCatData(_id);
  //   }
  // }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="container ani-ui-block shop-manager">
        <div className="row menu-top-block">
          <div className="col-sm-12 tl-block align-self-center">
            <h4 className="heading">Add Blog</h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="add-left-block">
                  <div className="fields-ui-block">
                    <div className="basic-details">
                      <div className="form-block">
                        <div className="row">
                          <div className="col-12">
                            <ImageUploaderField
                              control={control}
                              label="Cover Image*"X
                              name="featureImage"
                              uploadType="image"
                              setValue={setValue}
                              changeLabel={"image"}
                              uploadText="Upload image"
                              extensionTypes={["jpeg", "jpg", "png"]}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Cover image is required",
                                },
                              }}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <Inputfield
                              control={control}
                              label="Blog title*"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              name="title"
                              type="text"
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Blog title is required",
                                },
                              }}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-6">
                            <ReactSelectField
                              control={control}
                              name="groupId"
                              label={"Select Category*"}
                              placeholder="Select Category"
                              options={allCategories}
                              optionValue="id"
                              optionLabel="groupName"
                              // onSelect={(_id)=> { onSelectCategory(_id) }}
                              normalize={(value) => value?.id}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Category is required",
                                },
                              }}
                            />
                          </div>
                          <div className="col-6">
                            <Inputfield
                              control={control}
                              label="Author Name*"
                              name="authorName"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              type="text"
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Author Name is required",
                                },
                              }}
                            />
                          </div>

                          {/* <div className="col-6">
                            <ReactSelectField
                              control={control}
                              name="groupId"
                              label={"Select Sub Category"}
                              placeholder="Select Sub Category"
                              options={allSubCategories}
                              optionValue="id"
                              optionLabel="groupCode"
                              normalize={(value) => value?.id}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Sub Category is required",
                                },
                              }}
                            />
                          </div> */}
                        </div>

                        <div className="row">
                          {/* <div className="col-6">
                            <Inputfield
                              control={control}
                              label="Author Name"
                              name="authorName"
                              type="text"
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Author Name is required",
                                },
                              }}
                            />
                          </div> */}
                          <div className="col-6">
                            <InputTagField
                              control={control}
                              label="Add tags/keywords*"
                              name="keywords"
                              type="text"
                              mainClass="tag-wrpr form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Tag is required",
                                },
                              }}
                            />
                          </div>
                          <div className="col-6">
                            {" "}
                            <Inputfield
                              control={control}
                              label="Meta title*"
                              name="metaTitle"
                              type="text"
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Meta title is required",
                                },
                              }}
                            />
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col-12">
                            <Inputfield
                              control={control}
                              label="Meta title"
                              name="metaTitle"
                              type="text"
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Meta title is required",
                                },
                              }}
                            />
                          </div>
                        </div> */}
                        <div className="row">
                          <div className="col-lg-12">
                            <ReactSelectField
                              control={control}
                              name="viewer"
                              label={"Select Viewer*"}
                              placeholder="Select Viewer"
                              options={VIEWER_TYPE}
                              multi={true}
                              optionValue="id"
                              optionLabel="label"
                              normalize={(value) => value?.id}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Viewer is required",
                                },
                              }}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-12">
                            <InputTextAreaField
                              control={control}
                              name="metaDescription"
                              label={"Meta Description*"}
                              placeholder="Meta Description"
                              type="text"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Meta Description is required",
                                },
                              }}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <MultiImageUploaderField
                              control={control}
                              label="Upload Image"
                              name="attachment"
                              uploadType="image"
                              uploadText="Upload image"
                              extensionTypes={["jpeg", "jpg", "png"]}
                              onChangeImage={(_images) => setAllImages(_images)}
                              rules={{
                                required: {
                                  value: false,
                                  message: "Image is required",
                                },
                              }}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <UploadVideoField
                              control={control}
                              label="Upload Video"
                              name="attachmentVideo"
                              acceptedTypes={[".mov", ".mp4"]}
                              acceptedTypeExtension={["quicktime", "mp4"]}
                              setValue={setValue}
                              uploadText="Upload video"
                              rules={{
                                required: false,
                              }}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <JoditEdior
                              control={control}
                              name="description"
                              label="Description*"
                              errorClass="error-msg"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Description is required",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row save-button-block">
                    <div className="col-sm-12 align-self-center">
                      <div className="btn-wrpr d-flex justify-content-end add-blog-btns"> 
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          onClick={() => setStatus(0)}
                          className={`btn-full mr-2 orange-btn ${
                            isSubmitting && isLoadingPublishBtn == 1 ? "btn-loader" : ""
                          }`}
                        >
                          Save as draft
                        </button>

                        <button
                          type="submit"
                          onClick={() => setStatus(3)}
                          disabled={isSubmitting}
                          className={`btn-full orange-btn ${
                            isSubmitting  && isLoadingPublishBtn == 2 && "btn-loader"
                          }`}
                        >
                          Publish now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddBlog;

// import { useState } from "react";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";

import { resetPassword, verifyResetToken } from "../../actions/auth";
import Inputfield from "../../components/FormFields/InputField";

function ResetPasswordForm() {
  // use hooks
  const {
    control,
    handleSubmit,
    getValues,
    setError,
    // resetField,
    formState: { isSubmitting },
  } = useForm();

  // local variables
  const [token, setToken] = useState(null);

  // use hooks
  const { search } = useLocation();
  const navigator = useNavigate();

    // on Input change, checking both passwords are same or not
  const onInputChange = (value) => {
    if (value !== getValues("password")) {
      setError("confirmPassword", {
        type: "validate",
        message: "New and Confirm password both should be same",
      });
    }
  };

  // onSubmit form
  const onSubmit = async (formData) => {
    if (formData?.password !== formData?.confirmPassword) {
      setError("confirmPassword", {
        type: "validate",
        message: "New and Confirm password both should be same",
      });
    }else{
      try {
        await resetPassword({ password: formData?.password, token });
        toast.success("Your password has been set successfully");
        navigator("/login");
      } catch ({ response }) {
        toast.error(`${response?.data?.message}`);
      }
    }
  };


  useEffect(() => {
    const value = queryString.parse(search);
    setToken(value?.token);
  }, [setToken, search]);

  return (
    <>
      <div className="form-block-section" style={{ textAlign: "center" }}>
        <form className="p-t-15" onSubmit={handleSubmit(onSubmit)}>
          <Inputfield
            control={control}
            name={"password"}
            label={"New Password"}
            normalize={(e) => e.replace(/^\s+/g, "")}
            placeholder="New Password"
            type="password"
            inputClassName={"textfield"}
            rules={{
              required: { value: true, message: "Password is required" },
              minLength: {
                value: 4,
                message: "Password must be more than four characters",
              },
            }}
          />

          <Inputfield
            control={control}
            name={"confirmPassword"}
            label={"Confirm Password"}
            placeholder="Confirm Password"
            normalize={(e) => e.replace(/^\s+/g, "")}
            type="password"
            inputClassName={"textfield"}
            onChange={(value) => {
              onInputChange(value);
            }}
            rules={{
              required: {
                value: true,
                message: "Confirm Password is required",
              },
              minLength: {
                value: 4,
                message: "Confirm Password must be more than four characters",
              },
            }}
          />

          <button
            type="submit"
            disabled={isSubmitting}
            className={`btn-primary-full ${
              isSubmitting ? "btn-loader" : ''
            }`}
          >
            UPDATE PASSWORD
          </button>

          <Link
            style={{
              fontSize: "14px",
              color: "#8A8E94",
              fontWeight: "bold",
              letterSpacing: "0.2px",
              textTransform: "uppercase",
            }}
            to="/login"
          >
            Login
          </Link>
        </form>
      </div>
    </>
  );
}

export default ResetPasswordForm;

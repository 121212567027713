import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";

import Inputfield from "../../components/FormFields/InputField";
import ReactSelectField from "../../components/FormFields/ReactSelectField";
import { customerType, messageType } from "../../constants";
import InputTextAreaField from "../../components/FormFields/InputTextAreaField";

function EditBroadCast() {
  // use hooks
  const {
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const { id } = useParams();

  // on form submissions
  const onSubmit = (formData) => {
    // console.log(formData);
  };

  const getVetById = async () => {
    try {
      // const resp = await getVetDataById();
      const resp = {};
      setValue(
        "customerType",
        resp?.data?.responseData?.customerType || "Customer"
      );
      setValue("messageType", resp?.data?.responseData?.messageType || "SMS");
      setValue(
        "description",
        resp?.data?.responseData?.description || 'This is of type "sms"'
      );
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  };

  // usehooks
  useEffect(() => {
    if (id) {
      getVetById(id);
    }
  }, [id]);

  return (
    <>
      <div className="container ani-ui-block shop-manager">
        <div className="row menu-top-block">
          <div className="col-sm-12 tl-block align-self-center">
            <h4 className="heading">Edit</h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              {/* {detail === null && <div className="content-loader"></div>} */}
              <div className="col-lg-12 col-md-12">
                <div className="add-left-block">
                  <div className="fields-ui-block">
                    <div className="basic-details">
                      {/* <div className="heading">
                        <h4>Personal Informations</h4>
                      </div> */}
                      <div className="form-block">
                        <div className="row">
                          <div className="col-lg-6">
                            <ReactSelectField
                              control={control}
                              label="Customer Type"
                              name="customerType"
                              placeholder="Customer Type"
                              options={customerType}
                              optionValue="id"
                              optionLabel="label"
                              normalize={(_value) => _value?.value}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Customer Type is required",
                                },
                              }}
                            />
                          </div>
                          <div className="col-lg-6">
                            <ReactSelectField
                              control={control}
                              name="messageType"
                              label={"Message type"}
                              placeholder="Message Type"
                              options={messageType}
                              optionValue="id"
                              optionLabel="label"
                              normalize={(_value) => _value?.value}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Message Type is required",
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <InputTextAreaField
                              control={control}
                              name="description"
                              label={"Description"}
                              placeholder="Description"
                              type="text"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Description is required",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row save-button-block">
                    <div className="col-sm-12 align-self-center">
                      <div className="btn-wrpr">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className={`btn-full orange-btn ${
                            isSubmitting && "btn-loader"
                          }`}
                        >
                          Submit details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default EditBroadCast;

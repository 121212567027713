// export const BASE_URL = "https://api.nextofkinn.com/"
console.log(process.env.REACT_APP_APPA_ENV);
export const BASE_URL = process.env.REACT_APP_APP_ENV !== undefined ? process.env.REACT_APP_APP_ENV === "local" ? "http://localhost:3006" : process.env.REACT_APP_APP_ENV === "production" ? "https://api.nextofkinn.com/" : "https://api-dev2.nextofkinn.com/" : "https://api-dev2.nextofkinn.com/";
// export const BASE_URL = "http://localhost:3006/";

export const VIDEO_MAX_SIZE = 8;

export const limit = 20; // Don't change the value of limit otherwise pagination
// will be disturbed from all the places.

export const imagesToAccept = [".jpg", ".jpeg", ".png", ".gif", ".svg"];

// Field validations
export const errorType = [
  "manual",
  "required",
  "pattern",
  "validate",
  "minLength",
  "maxLength",
  "max",
  "min",
  "positive",
  "lessThanTen",
  "greaterThan",
  "checkUrl",
];

export const OPENING_TIME = [
  { value: "0", label: "12:00 am" },
  { value: "30", label: "12:30 am" },
  { value: "60", label: "01:00 am" },
  { value: "90", label: "01:30 am" },
  { value: "120", label: "02:00 am" },
  { value: "150", label: "02:30 am" },
  { value: "180", label: "03:00 am" },
  { value: "210", label: "03:30 am" },
  { value: "240", label: "04:00 am" },
  { value: "270", label: "04:30 am" },
  { value: "300", label: "05:00 am" },
  { value: "330", label: "05:30 am" },
  { value: "360", label: "06:00 am" },
  { value: "390", label: "06:30 am" },
  { value: "420", label: "07:00 am" },
  { value: "450", label: "07:30 am" },
  { value: "480", label: "08:00 am" },
  { value: "510", label: "08:30 am" },
  { value: "540", label: "09:00 am" },
  { value: "570", label: "09:30 am" },
  { value: "600", label: "10:00 am" },
  { value: "630", label: "10:30 am" },
  { value: "660", label: "11:00 am" },
  { value: "690", label: "11:30 am" },
  { value: "720", label: "12:00 pm" },
  { value: "750", label: "12:30 pm" },
  { value: "780", label: "01:00 pm" },
  { value: "810", label: "01:30 pm" },
  { value: "840", label: "02:00 pm" },
  { value: "870", label: "02:30 pm" },
  { value: "900", label: "03:00 pm" },
  { value: "930", label: "03:30 pm" },
  { value: "960", label: "04:00 pm" },
  { value: "990", label: "04:30 pm" },
  { value: "1020", label: "05:00 pm" },
  { value: "1050", label: "05:30 pm" },
  { value: "1080", label: "06:00 pm" },
  { value: "1110", label: "06:30 pm" },
  { value: "1140", label: "07:00 pm" },
  { value: "1170", label: "07:30 pm" },
  { value: "1200", label: "08:00 pm" },
  { value: "1230", label: "08:30 pm" },
  { value: "1260", label: "09:00 pm" },
  { value: "1290", label: "09:30 pm" },
  { value: "1320", label: "10:00 pm" },
  { value: "1350", label: "10:30 pm" },
  { value: "1380", label: "11:00 pm" },
  { value: "1410", label: "11:30 pm" },
  { value: "1439", label: "11:59 pm" },
];

export const OPENING_CLOSING_TIME = [
  { value: "30", label: "12:29 am" },
  { value: "60", label: "12:59 am" },
  { value: "90", label: "01:29 am" },
  { value: "120", label: "01:59 am" },
  { value: "150", label: "02:29 am" },
  { value: "180", label: "02:59 am" },
  { value: "210", label: "03:29 am" },
  { value: "240", label: "03:59 am" },
  { value: "270", label: "04:29 am" },
  { value: "300", label: "04:59 am" },
  { value: "330", label: "05:29 am" },
  { value: "360", label: "05:59 am" },
  { value: "390", label: "06:29 am" },
  { value: "420", label: "06:59 am" },
  { value: "450", label: "07:29 am" },
  { value: "480", label: "07:59 am" },
  { value: "510", label: "08:29 am" },
  { value: "540", label: "08:59 am" },
  { value: "570", label: "09:29 am" },
  { value: "600", label: "09:59 am" },
  { value: "630", label: "10:29 am" },
  { value: "660", label: "10:59 am" },
  { value: "690", label: "11:29 am" },
  { value: "720", label: "11:59 am" },
  { value: "750", label: "12:29 pm" },
  { value: "780", label: "12:59 pm" },
  { value: "810", label: "01:29 pm" },
  { value: "840", label: "01:59 pm" },
  { value: "870", label: "02:29 pm" },
  { value: "900", label: "02:59 pm" },
  { value: "930", label: "03:29 pm" },
  { value: "960", label: "03:59 pm" },
  { value: "990", label: "04:29 pm" },
  { value: "1020", label: "04:59 pm" },
  { value: "1050", label: "05:29 pm" },
  { value: "1080", label: "05:59 pm" },
  { value: "1110", label: "06:29 pm" },
  { value: "1140", label: "06:59 pm" },
  { value: "1170", label: "07:29 pm" },
  { value: "1200", label: "07:59 pm" },
  { value: "1230", label: "08:29 pm" },
  { value: "1260", label: "08:59 pm" },
  { value: "1290", label: "09:29 pm" },
  { value: "1320", label: "09:59 pm" },
  { value: "1350", label: "10:29 pm" },
  { value: "1380", label: "10:59 pm" },
  { value: "1410", label: "11:29 pm" },
  { value: "1439", label: "11:59 pm" },
];

export const DAYS = [
  {
    name: "S",
    label: "Sunday",
    value: 6,
  },
  {
    name: "M",
    label: "Monday",
    value: 0,
  },
  {
    name: "T",
    label: "Tuesday",
    value: 1,
  },
  {
    name: "W",
    label: "Wednesday",
    value: 2,
  },
  {
    name: "T",
    label: "Thursday",
    value: 3,
  },
  {
    name: "F",
    label: "Friday",
    value: 4,
  },
  {
    name: "S",
    label: "Saturday",
    value: 5,
  },
];

export const MONTHS = [
  {
    name: "January",
    value: 1,
  },
  {
    name: "February",
    value: 2,
  },
  {
    name: "March",
    value: 3,
  },
  {
    name: "April",
    value: 4,
  },
  {
    name: "May",
    value: 5,
  },
  {
    name: "June",
    value: 6,
  },
  {
    name: "July",
    value: 7,
  },
  {
    name: "August",
    value: 8,
  },
  {
    name: "September",
    value: 9,
  },
  {
    name: "October",
    value: 10,
  },
  {
    name: "November",
    value: 11,
  },
  {
    name: "December",
    value: 12,
  },
];

export const DAYS_TYPE = [
  {
    name: "Days",
    label: "Day",
    title: "Daily",
    value: 1,
  },
  {
    name: "Weeks",
    label: "Week",
    title: "Weekly",
    value: 2,
  },
  {
    name: "Months",
    label: "Month",
    title: "Monthly",
    value: 3,
  },
  {
    name: "Years",
    label: "Year",
    title: "Yearly",
    value: 4,
  },
];

export const DAY_REPEAT_VALUES = [
  {
    name: "Never",
    value: "never",
  },
  {
    name: "On",
    value: "on",
  },
  {
    name: "After",
    value: "after",
  },
];

export const MONTH_COUNT = [
  {
    name: "First",
    value: 1,
  },
  {
    name: "Second",
    value: 2,
  },
  {
    name: "Third",
    value: 3,
  },
  {
    name: "Fourth",
    value: 4,
  },
  {
    name: "Last",
    value: 0,
  },
];

// types of gender
export const genderTypes = [
  {
    id: 1,
    label: "Male",
    value: "male",
  },
  {
    id: 2,
    label: "Female",
    value: "female",
  },
];

// profeesional types
export const professionalType = [
  {
    id: 1,
    label: "Single Practitioner",
    value: "Single Practitioner",
  },
  {
    id: 2,
    label: "Clinic",
    value: "Clinic",
  },
];

// types of customers
export const customerType = [
  {
    id: 1,
    label: "Customer",
    value: "customer",
  },
  {
    id: 2,
    label: "Veterinarians",
    value: "veterinarians",
  },
];

// message sending type
export const messageType = [
  {
    id: 1,
    label: "SMS",
    value: "sms",
  },
  {
    id: 2,
    label: "Email",
    value: "email",
  },
];

export const GROUP_TYPES = [
  {
    id: "cms",
    label: "CMS",
  },
  {
    id: "category",
    label: "Category",
  },
];

export const VIEWER_TYPE = [
  // {
  //   id: 1,
  //   label: "Admin",
  // },
  {
    id: 2,
    label: "Vet",
  },
  {
    id: 3,
    label: "User",
  },
];

export const BlogImagesSlickSetting = {
  dots: true,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  arrows: true,
  autoplay: true,
  fade: true,
  cssEase: "linear",
};

export const ALL_RATINGS = [
  {
    code: "1",
    name: "Frustrating",
    desc: "This is unacceptable! We will look into this.",
    imgUrl: "/assets/images/Frustrating.svg",
  },
  {
    code: "2",
    name: "Bad",
    desc: "Apologies for that. We have noted and will work on this.",
    imgUrl: "/assets/images/bad.svg",
  },
  {
    code: "3",
    name: "Normal",
    desc: "Not good for us. We will improve.",
    imgUrl: "/assets/images/normal.svg",
  },
  {
    code: "4",
    name: "Good",
    desc: "Happy to know. We will work on this so that it becomes 'wow'",
    imgUrl: "/assets/images/good.svg",
  },
  {
    code: "5",
    name: "Wow!",
    desc: "There is nothing better than a happy you!",
    imgUrl: "/assets/images/wow.svg",
  },
];

export const rolesArrayList = [
  {
    name: "Doctor",
    value: 1,
  },
  {
    name: "Assistant",
    value: 2,
  },
  {
    name: "Nurse",
    value: 3,
  },
];

export const ALL_REVIEWS = [
  {
    code: "1",
    name: "Report lost or found"
  },
  {
    code: "2",
    name: "Report dead",
  },
  {
    code: "3",
    name: "Report abused",
  },
  {
    code: "4",
    name: "Report injured",
  },
  {
    code: "5",
    name: "App is not working properly",
  },
  {
    code: "6",
    name: "I am not able to add staff members",
  },
  {
    code: "7",
    name: "App is taking too long to load things",
  },
  {
    code: "8",
    name: "Other",
  },
];

export const PET_HEALTH_STATUS = [
  {
    label: "Dead",
    value: 0,
  },
  {
    label: "Alive",
    value: 1,
  },
  {
    label: "Lost",
    value: 2,
  },
  {
    label: "Found",
    value: 3,
  },
];

export const MEDICATION_ATTRIBUTE_TYPE = [
  {
    label: "Diagnostic Test",
    value: 1,
  },
  {
    label: "Visit Reason",
    value: 2,
  },
  {
    label: "Diagnosis",
    value: 3,
  },
  {
    label: "Presenting Complaint",
    value: 4,
  },
];


import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import Inputfield from "../../../components/FormFields/InputField";
import ReactPhoneInput from "../../../components/FormFields/ReactPhoneInput";
import ReactSelectField from "../../../components/FormFields/ReactSelectField";
import { genderTypes, professionalType } from "../../../constants";
import { addVet } from "../../../actions/Users";
import {getTimeSlots} from "../../../actions/timeSlot";
import { useEffect, useState } from "react";
import ImageUploaderField from "../../../components/FormFields/ImageUploaderField";

var countryList = require("country-data-list").countries;

function AddVet() {

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { isSubmitting },
  } = useForm();


  const [slots, setSlots] = useState([]);

  let schedule = [
    {
        "frequency": [
            "1"
        ],
        "type": 1,
        "timings": [
            {
                "startTime": "540",
                "endTime": "1020"
            }
        ],
        "groupId": 5,
        "title": "Working Hours",
        "allDay": 0,
        "startDate": "2023-02-21T10:26:07.501Z"
    }
];


let returnData = {
    "workinghr": [
      {
        "frequency": "1",
        "type": 1,
        "repeatType": "never",
        "timings": [
          {
            "startTime": "540",
            "endTime": "1020"
          }
        ]
      }
    ]
  };

  const navigate = useNavigate();

  const getAllSlots = async () => {
    try {
      const resp = await getTimeSlots();
      setSlots(resp?.data?.responseData?.help);
    } catch ({ response, request }) {
      
    }
  };

  useEffect(() => {
    getAllSlots();
  }, []);

  // on form submissions
  const onSubmit = async(formData) => {
    // delete(formData.country);
    formData = {...formData, country: formData.country.name, schedule: schedule, returnData: returnData, duration: formData?.duration?.value ?? 0}
    console.log('___________',formData);
    try {
      await addVet({ ...formData});
      navigate("/dashboard/vets");
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  };

  return (
    <>
      <div className="container ani-ui-block shop-manager">
        <div className="row menu-top-block">

          <div className="data-header-left cr-operators">
            <div className="count-data">
              <span className="pr-page"><Link to="/dashboard/vets" className="link-hrf">Vets </Link> </span>
            </div>
            <div className="count-day lk-path">
              <span className="st-current">Add Vet</span>
            </div>
          </div>



          <div className="col-sm-12 tl-block align-self-center">
            <h4 className="heading">Add Veterinarian Details</h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="add-left-block">
                  <div className="fields-ui-block">
                    <div className="basic-details">
                      <div className="heading">
                        <h4>Personal Informations</h4>
                      </div>
                      <div className="form-block">
                        <div className="row">
                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              label="Name"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              name="name"
                              type="text"
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Full Name is required",
                                },
                                maxLength: {
                                  value: 40,
                                  message: "Maximum 40 characters are allowed!",
                                },
                              }}
                            />
                          </div>
                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              label="Email"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              name="email"
                              type="text"
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Email is required",
                                },
                                pattern: {
                                  value:
                                    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                                  message: "Please enter valid email address",
                                },
                              }}
                            />
                          </div>

                          <div className="col-lg-4 phn-input-chk">
                            <ReactPhoneInput
                              label="Phone Number*"
                              placeholder="Enter phone number"
                              control={control}
                              countryCodeValue={{
                                countryCodeValue: "countryCodeValue",
                                defaultCountryCodeValue: "",
                              }}
                              countryCode={{
                                countryCodeName: "countryCode",
                                countryCodeRules: {
                                  required: {
                                    value: true,
                                  },
                                },
                                defaultValue: "",
                              }}
                              phoneNumber={{
                                phoneName: "phoneNumber",
                                phoneType: "text",
                                phoneRules: {
                                  required: {
                                    value: true,
                                    message: "Phone number is required",
                                  },
                                  minLength: {
                                    value: 6,
                                    message:
                                      "Phone Number must not be less than 6 digits",
                                  },
                                  maxLength: {
                                    value: 16,
                                    message: "Number must not be greater than 16 digits",
                                  },
                                },
                                phoneDefaultValue: "",
                              }}
                            />
                          </div>

                          <div className="col-lg-4">
                            <ReactSelectField
                              control={control}
                              name="gender"
                              label="Gender"
                              placeholder="Select Gender"
                              options={genderTypes}
                              optionValue="value"
                              optionLabel="label"
                              normalize={(value) => value?.label}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Gender is required",
                                },
                              }}
                            />
                          </div>
                          <div className="col-lg-4">
                            <ReactSelectField
                              control={control}
                              label="Country*"
                              name="country"
                              placeholder="Select country"
                              options={countryList.all}
                              optionValue="name"
                              optionLabel="name"
                              rules={{
                                required: { value: true, message: "Country is required" },
                              }}
                            />
                          </div>

                        </div>
                      </div>
                    </div>

                    <div className="basic-details">
                      <div className="heading">
                        <h4>Professional Informations</h4>
                      </div>
                      <div className="form-block">
                        <div className="row">
                          <div className="col-lg-4">
                            <ReactSelectField
                              control={control}
                              name="role"
                              placeholder="Select Type"
                              label="Type"
                              options={professionalType}
                              optionValue="value"
                              optionLabel="label"
                              normalize={(value) => value?.id}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Type is required",
                                },
                              }}
                            />
                          </div>
                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              label="Clinic Name"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              name="clinicName"
                              type="text"
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Clinic Name is required",
                                },
                              }}
                            />
                          </div>

                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              label="No. of attendees*"
                              name="slots"
                              type="number"
                              placeholder='No. of attendees'
                              rules={{
                                required: { value: true, message: 'Attendees is required' },
                              }}
                            />
                          </div>

                          <div className="col-lg-4">
                            <ReactSelectField
                              control={control}
                              label="Appointment Duration*"
                              name="duration"
                              placeholder="Appointment Duration"
                              options={slots?.map((_slot) => {
                                return {
                                  name: `${_slot?.time} minutes`,
                                  value: _slot?.time,
                                };
                              })}
                              optionValue="value"
                              optionLabel="name"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Duration is required",
                                },
                              }}
                            />
                          </div>

                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              label="Clinic Address"
                              name="clinicAddress"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              placeholder="Clinic Address"
                              rules={{
                                required: {
                                  value: true,
                                  message: 'Clinic address is required',
                                },
                                maxLength: {
                                  value: 100,
                                  message: "Address length should not be greater than 100",
                                },
                              }}
                            />
                          </div>
                        </div>


                        <div className="row">
                          <div className="col-12">
                            <ImageUploaderField
                              control={control}
                              label="Clinic Image"
                              name="profile"
                              uploadType="image"
                              setValue={setValue}
                              changeLabel="Clinic Image"
                              extensionTypes={["jpeg", "jpg", "png"]}
                              uploadText="Upload clinic image"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Clinic image is required",
                                },
                              }}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <ImageUploaderField
                              control={control}
                              label="Upload digital signature"
                              name="signature"
                              uploadType="image"
                              setValue={setValue}
                              changeLabel="Upload digital signature"
                              extensionTypes={["jpeg", "jpg", "png"]}
                              uploadText="Upload digital signature"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Digital signaure is required",
                                },
                              }}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <ImageUploaderField
                              control={control}
                              label="licence file"
                              name="licence"
                              uploadType="file"
                              setValue={setValue}
                              changeLabel="licence file"
                              uploadText="Upload licence file"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Licence file is required",
                                },
                              }}
                            />
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>

                  <div className="row save-button-block">
                    <div className="col-sm-12 align-self-center">
                      <div className="btn-wrpr">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className={`btn-full orange-btn ${
                            isSubmitting && "btn-loader"
                          }`}
                        >
                          Submit details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddVet;

import { useEffect, useState } from "react";
import { useController } from "react-hook-form";
import { removeFile, uploadFile } from "../../actions/auth";
import { BASE_URL, errorType } from "../../constants";
import { editBlogs } from '../../actions/cms'
import Loader from "../Common/loader";

function MultiImageUploaderField({
  postId,
  control,
  name,
  rules,
  uploadType,
  label,
  changeLabel,
  uploadText,
  setValue,
  extensionTypes,
  defaultImages,
  borderClass,
  onChangeImage,
}) {
  // use hooks
  const {
    field,
    fieldState: { error },
  } = useController({ control, name, rules });

  const [isLoading, setIsLoading] = useState(false);
  const [exntensionError, setExtensionError] = useState(null);
  const [images, setImages] = useState([]);

  // on image change
  const onImageChange = async (e, type, index) => {
    const formData = new FormData();
    let file = e?.target?.files[0];
    let currentFileExtension = file?.type.split("/")[1];
    formData.append("files", e?.target?.files[0]);
    if (
      extensionTypes?.length > 0 &&
      !extensionTypes.includes(currentFileExtension)
    ) {
      field.onChange("");
      setExtensionError(true);
      return;
    }

    setIsLoading(true);
    setExtensionError(false);

    try {
      const resp = await uploadFile(formData);
      let allImages = [...images];
      if (type === "add") {
        allImages.push(resp?.data?.responseData[0]);
      } else {
        allImages[index] = resp?.data?.responseData[0];
      }
      setImages(allImages);
      onChangeImage(allImages);
      setIsLoading(false);
      e.target.value = '';
    } catch ({ request, response }) {
      setIsLoading(false);
      if (response) {
        // toast.error(response?.data?.message);
      } else if (request) {
        // toast.error("You do not have internet connection");
      }
    }
  };
  const onRemoveImage = async (id) => {
    setIsLoading(true);
    try {
      let allImages = [...images];
      let restImages = allImages.filter((img) => img.id !== id);
      await editBlogs({id: postId, attachment: restImages})
      await removeFile({ ids: id });
      // console.log(restImages);

      if (allImages?.length > 0) {
        const foundIndex = allImages?.findIndex((_img) => _img?.id === id);
        if (foundIndex > -1) {
          allImages.splice(foundIndex, 1);
        }
      }
      setImages(allImages);
      if (onChangeImage) onChangeImage(allImages);
      setIsLoading(false);
    } catch ({ request, response }) {
      setIsLoading(false);
      if (response.status == 400) {
        let allImages = [...images];
        if (allImages?.length > 0) {
          const foundIndex = allImages?.findIndex((_img) => _img?.id === id);
          if (foundIndex > -1) {
            allImages.splice(foundIndex, 1);
          }
        }
        setImages(allImages);
        if (onChangeImage) onChangeImage(allImages);
        setIsLoading(false);
        // toast.error(response?.data?.message);
      } else if (request) {
        // toast.error("You do not have internet connection");
      }
    }
  };

  // use hook
  useEffect(()=> {
    if(defaultImages?.length>0){
      setImages(defaultImages);
    }
  },[defaultImages]);

  return (
    <>
      <div className={`form-in w-100 ${error ? "required-field" : ""}`}>
        <label className="f-label">{label}</label>
        <div className="f-in w-100">
          <div className="image-upload">
            <span className="img-upload">
              <img src="/assets/images/upload-img.svg" />
            </span>
            <span className="img-text">
              {uploadText}
              {uploadType === "image" && <span>(</span>}
              {`${uploadType === "image" ? extensionTypes : ""}`}
              {uploadType === "image" && <span>)</span>}
            </span>
            {isLoading &&  <Loader />}
            <input
              {...field}
              type="file"
              placeholder=""
              title=""
              className="form-control"
              onChange={(e) => {
                onImageChange(e, "add");
              }}
            />
          </div>
        </div>

        <div className="edited-fiels w-100">
          {uploadType === "image" ? (
            <div className="img-wrapper">
              {images?.length > 0 &&
                images?.map((_img, index) => (
                  <div
                    className={`upload-image ${borderClass ? borderClass : ""}`}
                    key={index}
                  >
                    <figure className="img-figure">
                      <img
                        src={`${BASE_URL}${_img?.path}`}
                        className="full-width"
                      />
                    </figure>
                    <a href="#" className="link fw600 edit-btn">
                      <span className="link-icon dark"> Change{" "}
                      {changeLabel} 
                      {uploadType === "image" && <span> (</span>}
                      {`${uploadType === "image" ? extensionTypes : ""}`}
                      {uploadType === "image" && <span>)</span>}
                      </span>
                      <input
                        type="file"
                        title=""
                        placeholder=""
                        className="form-control edit-image"
                        onChange={(e) => {
                          onImageChange(e, "change", index);
                        }}
                      />
                    </a>
                    <a
                      href="javascript:;"
                      className="link fw600 remove-btn"
                      onClick={() => onRemoveImage(_img?.id)}
                    >
                      Remove
                    </a>
                  </div>
                ))}
            </div>
          ) : (
            <div className="img-wrapper">
              {images?.length > 0 &&
                images?.map((_img, index) => (
                  <div
                    className={`upload-image ${borderClass ? borderClass : ""}`}
                    key={index}
                  >
                    <div className="document-card">
                      <span className="document-icon">
                        <img
                          src="/images/icons/document.svg"
                          alt=""
                          className="img-fluid"
                        />
                      </span>
                      <span className="document-name text-gray-900">
                        {_img?.originalName}
                      </span>
                    </div>
                    <a href="#" className="link fw600 edit-btn">
                      <span className="link-icon dark">Change{" "}
                      {changeLabel} {uploadType === "image" && <span> (</span>}
                      {`${uploadType === "image" ? extensionTypes : ""}`}
                      {uploadType === "image" && <span>)</span>}
                      </span>
                      <input
                        type="file"
                        placeholder=""
                        title=""
                        className="form-control edit-image"
                        onChange={(e) => {
                          onImageChange(e, "change", index);
                        }}
                      />
                    </a>
                    <a
                      href="javascript:;"
                      className="link fw600 remove-btn"
                      onClick={() => onRemoveImage(_img?.id)}
                    >
                      Remove
                    </a>
                  </div>
                ))}
            </div>
          )}
        </div>

        {exntensionError ? (
          <p className="error">
            Invalid file extension. Allowed file extensions are{" "}
            {extensionTypes?.map(
              (_type, index) =>
                `${_type} ${
                  extensionTypes?.length - 1 == index
                    ? ""
                    : extensionTypes?.length - 2 == index
                    ? "and "
                    : ", "
                }`
            )}{" "}
          </p>
        ) : null}

        {errorType?.map((type) => {
          if (error?.type === type && error?.message !== "") {
            return (
              <p key={type} className="error">
                {error?.message}
              </p>
            );
          }
        })}
      </div>
    </>
  );
}

export default MultiImageUploaderField;

import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-hot-toast';

import Inputfield from "../../components/FormFields/InputField";
import ImageUploaderField from "../../components/FormFields/ImageUploaderField";
import { getSpeciesById, UpdateSpeciesStatus } from "../../actions/animal";
import { getCaregiverRole, editCaregiverRole } from "../../actions/caregiver";

function EditCaregiver() {
  // use hooks
  const {
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const { id } = useParams();
  const navigate = useNavigate();

  // on form submissions
  const onSubmit = async(formData) => {
    try {
      await editCaregiverRole({ 'name': formData.name, id });
      navigate(-1);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  };

  const getResultWithId = async() => {
    try{
      const resp = await getCaregiverRole({id});
      setValue('name', resp?.data?.responseData?.role?.name ?? null);
    }catch({response, request}){
      if(response){
        toast.error(response?.data?.message)
      }else if(request){
        toast.error('You do not have internet connection');
      }
    }
  }

  // usehooks
  useEffect(()=> {
    if(id){
      getResultWithId(id);
    }
  },[id])

  return (
    <>
      <div className="container ani-ui-block shop-manager">
        <div className="row menu-top-block">
          <div className="col-sm-12 tl-block align-self-center">
            <h4 className="heading">Edit Caregiver Role</h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="add-left-block">
                  <div className="fields-ui-block">
                    <div className="basic-details">
                      <div className="heading">
                        <h4>Basic Details</h4>
                      </div>
                      <div className="form-block">
                        <div className="row">
                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              label="Name*"
                              name="name"
                              type="text"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Name is required",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row save-button-block">
                    <div className="col-sm-12 align-self-center">
                      <div className="btn-wrpr">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className={`btn-full orange-btn ${
                            isSubmitting && "btn-loader"
                          }`}
                        >
                          Submit details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default EditCaregiver;

import { Modal } from "react-bootstrap";
import { BASE_URL } from "../../../constants";

function DetailModal({ show, onHide, data, title }) {
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        className="modal fade"
        dialogClassName="modal-dialog modal-lg w700"
      >
        <div className="modal-header">
          <div className="title-btn-wrpr">
            <h2 className="modal-title mr20">{title}</h2>
          </div>
          <div className="table-btns modal-btns">
            <button type="button" className="close" onClick={onHide}>
              <img src="/assets/images/close-icon-dark.svg" />
            </button>
          </div>
        </div>

        <div className="modal-body">
          <div className="form-block vendor-details-modal">
            <div className="row">
              <div className="col-12">
                <div className="info-ui">
                  <label>Title:</label>
                  <p>{data?.title}</p>
                </div>
              </div>

              {data?.species ? (
                <div className="col-12">
                  <div className="info-ui">
                    <label>Species:</label>
                    <p>{data?.species}</p>
                  </div>
                </div>
              ) : null}

              {data?.microchip ? (
                <div className="col-12">
                  <div className="info-ui">
                    <label>Microchip Number:</label>

                    <p>{data?.microchip}</p>
                  </div>
                </div>
              ) : null}

              <div className="col-12">
                <div className="info-ui">
                  <label>Description:</label>

                  <p>{data?.description}</p>
                </div>
              </div>

              {data?.attachment?.length > 0 ? (
                <div className="col-12">
                  <div className="info-ui">
                    <label>Uploaded Images:</label>
                    <div className="img-wrpr-report">
                      {data?.attachment?.map((_img, index) => (
                        <div className="single-img" key={index}>
                          <img
                            className="img-fluid"
                            src={`${BASE_URL}${_img?.path}`}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : null}

              {data?.imgUrl ? (
                <div className="col-12">
                  <div className="info-ui">
                    <label>Reaction:</label>
                    <div className="img-wrpr-report">
                      <div className="single-img">
                        <img className="img-fluid" src={data?.imgUrl} />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default DetailModal;

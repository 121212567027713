import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Inputfield from "../../../components/FormFields/InputField";
import { removeEmptyFields } from "../../../utilities";

function VetFilter({ getFilterFields }) {
  // use hooks
  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm();

  const navigate = useNavigate();

  //   } = useForm({ defaultValues: queryString.parse(search) });

  const onSubmit = (data) => {
    const values = removeEmptyFields(data);
    const queryParams = new URLSearchParams(values);
    navigate({
      search: queryParams.toString(),
    });
    getFilterFields(values);
  };

  const resetFilter = () => {
    navigate({
      search: "",
    });
    getFilterFields({});
    reset({ name: "", email: "", phone: "" });
  };

  return (
    <>
      <form name="filter" onSubmit={handleSubmit(onSubmit)}>
        <div className="row filter-block">
          <div className="col-sm-12 col-md-12 align-self-center">
            <div className="row align-items-end">
              <div className="col-lg-3">
                <Inputfield
                  control={control}
                  normalize={(e) => e.replace(/^\s+/g, "")}
                  label="Name"
                  placeholder=""
                  name="name"
                  type="text"
                  fieldClass="form-control"
                />
              </div>
              <div className="col-lg-3">
                <Inputfield
                  control={control}
                  normalize={(e) => e.replace(/^\s+/g, "")}
                  label="Email"
                  name="email"
                  placeholder=""
                  type="text"
                  fieldClass="form-control"
                />
              </div>

              <div className="col-lg-3">
                <Inputfield
                  control={control}
                  normalize={(e) => e.replace(/^\s+/g, "")}
                  label="Phone No. (without country code)"
                  name="phone"
                  placeholder=""
                  type="number"
                  fieldClass="form-control"
                />
              </div>

              {/* <div className="col-lg-3">
                <ReactPhoneInput
                  control={control}
                  phoneNumber={{
                    phoneName: "phone",
                    phoneRules: {
                      required: {
                        value: false,
                        message: "Phone Number is required",
                      }
                    },
                    defaultPhoneValue: "",
                  }}
                  countryCode={{
                    countryCodeName: "countryCode",
                    defaultValue: "",
                    countryCodeRules: {
                      required: {
                        value: false,
                        message: "CountryCode is required",
                      },
                    },
                    defaultCountryCodeValue: "",
                  }}
                  name="phone"
                  label="Phone no."
                  placeholder="Enter Phone Number"
                  defaultCountry={"sg"}
                  inputClass="phone-input"
                />
              </div> */}
              <div className="col-lg-3">
                <div className="row">
                  <div className="col-6 align-self-center">
                    <div className="filter-result-block form-group">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`btn btn1 btn-center ${
                          isSubmitting ? "btn-loader" : ''
                        }`}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                  <div className="col-6 align-self-center">
                    <div className="filter-result-block form-group">
                      <button
                        type="button"
                        onClick={resetFilter}
                        className="btn btn1 btn-center"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default VetFilter;

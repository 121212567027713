import { Link } from "react-router-dom";
import HeaderNavigation from "./HeaderNavigation";

function Header() {
  return (
    <>
      <header className="header-main">
        <div className="header-top-block">
          <div className="container">
            <div className="row">
              <div className="col-sm-4 col-5 align-self-center">
                <div className="logo">
                  <button className="ham-btn">
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>

                  <Link to="/dashboard">
                  <img src="/assets/images/logo.png" alt="Kinn" />
                  </Link>
                </div>
              </div>
              <div className="col-sm-8 col-7 online-block align-self-center">
                <HeaderNavigation />
              </div>
            </div>
          </div>
        </div>
        <div className="header-bottom-block" id="header-sticky">
          <div className="container">
            <div className="row">
              <div className="col-sm-4 align-self-center">
                <div className="order-tabs-block"></div>
              </div>
              <div className="col-sm-8 align-self-center"></div>
            </div>
          </div>
        </div>
        <div className="n-overlay"></div>
      </header>
    </>
  );
}

export default Header;

import moment from "moment";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useController } from "react-hook-form";

import { errorType } from "../../constants";

function ReactDatePicker({
  name,
  control,
  rules,
  defaultValue,
  minDate,
  maxDate,
  label,
  errorClass,
}) {
  // react form hooks
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });

  const [date, setDate] = useState(null)

  //on change date
  const onChangeDate = (date) => {
    setDate(date);
    // setting form value
    if(date){
      field.onChange(moment(date).format("DD-MM-YYYY"));
    }
  };

  useEffect(()=> {
    // field.onChange(field.value);
    // setDate(field.value)
  },[field])

  return (
    <>
      <div className="form-group">
        {label && <label>{label}</label>}
        <div className="textfield-block">
          <DatePicker
            selected={date}
            maxDate={maxDate}
            onChange={(date) => onChangeDate(date)}
          />

          {errorType?.map((type) => {
            if (error?.type === type && error?.message !== "") {
              return (
                <span key={type} className={errorClass || "error-msg"}>
                  {error?.message}
                </span>
              );
            }
          })}
        </div>
      </div>
    </>
  );
}

export default ReactDatePicker;

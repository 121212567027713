import moment from "moment";
import { useEffect, useState, useCallback } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import { getAnimalDetail } from "../../../actions/animal";

function AnimalDetail() {
  // local variables
  const [detail, setDetail] = useState(null);
  // use hooks
  const location = useLocation();

  const { id } = useParams();


  const fetchData = useCallback( async (userId) => {
   try {
      const resp = await getAnimalDetail({id: id});
      setDetail(resp?.data?.responseData);   
   } catch ({ response, request }) {
     if (response) {
       toast.error(response?.data?.message);
     } else if (request) {
       toast.error("You do not have internet connection");
     }
   }
  },[]);


  useEffect(() => {
    fetchData(id);
  }, []);

  console.log(detail, '========');



  return (
    <>
       <div className="row menu-top-block">

        <div className="data-header-left cr-operators">
          <div className="count-data">
            <span className="pr-page"><Link to="/dashboard/animals" className="link-hrf">Animals </Link> </span>
          </div>
          <div className="count-day lk-path">
            <span className="st-current">{detail?.name ?? null}</span>
          </div>
        </div>


      </div>
      {
        detail?.uniqueId
        ?
        <div className="container ani-ui-block shop-manager">
          <div className="row menu-top-block">
            <div className="col-sm-12 tl-block align-self-center">
              <h4 className="heading">Detail</h4>
            </div>
          </div>
          <div className="row menu-top-block">
            <div className="col-lg-12 col-md-12">
              <div className="add-left-block">
                <div className="fields-ui-block">
                  <div className="basic-details form-block">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="info-ui">
                          <label>Unique Id:</label>
                          <p>{detail?.uniqueId ?? null}</p>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="info-ui">
                          <label>Name:</label>
                          <p>{detail?.name ?? null}</p>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="info-ui">
                          <label>Species</label>
                          <p>{detail?.Specie?.name ?? null}</p>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="info-ui">
                          <label>Breed</label>
                          <p>{detail?.Bred?.name ?? null}</p>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="info-ui">
                          <label>License</label>
                          <p>{detail?.licenseNumber ?? null}</p>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="info-ui">
                          <label>Microchip</label>
                          <p>{detail?.microchipNumber ?? null}</p>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="info-ui">
                          <label>DOB</label>
                          <p>{detail?.dob ? moment(detail?.dob).format("lll") : null}</p>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="info-ui">
                          <label>Owner</label>
                          <p>{detail?.Owner?.data?.UserProfile?.name ?? null}</p>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="info-ui">
                          <label>Caregivers</label>
                          <ul>
                            {
                              detail?.caregivers?.length && detail?.caregivers?.map((obj, index) => (
                                <li>{obj?.Caregiver?.caregiver?.data?.UserProfile?.name ?? null}</li>
                              ))
                            }
                          </ul>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        null
      }

      
    </>
  );
}

export default AnimalDetail;

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment";
import { toast } from "react-hot-toast";
import { useEffect, useState } from "react";

import { getBlogById } from "../../../actions/cms";
import { useParams } from "react-router-dom";
import { BASE_URL, BlogImagesSlickSetting } from "../../../constants";
import Loader from "../../../components/Common/loader";

function BlogDetail() {
  // local variabes
  const [blogData, setBlogData] = useState(null);

  // use hooks
 
  const { id } = useParams();

  const getBlogWithId = async (_id) => {
    try {
      const resp = await getBlogById({ id: _id });
      setBlogData(resp?.data?.responseData);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  };

  useEffect(() => {
    if (id) {
      getBlogWithId(id);
    }
  }, [id]);

  return (
    <>
      <div className="dash-inner-container">
        {blogData === null ? (
          <Loader />
        ) : (
          <div className="bg-white w-100 blog-detail-wrpr">
            <div className="d-flex align-center top-bar">
              {blogData?.Group?.defaultContent?.[0]?.groupName ? (
                <p className="fs12 fw700 blog-species dog">
                  {blogData?.Group?.defaultContent?.[0]?.groupName}
                </p>
              ) : null}

              <p className="d-flex align-center blog-owner">
                <span className="blog-owner-img">
                  <img
                    src="/assets/images/female.png"
                    className="img-fluid"
                    alt="owner-img"
                  />
                </span>
                <span className="text-dark fs11 fw600">
                  By {blogData?.authorName}
                </span>
              </p>
              <p className="text-gray-800 fs11 fw600 blog-date">
                {moment(blogData?.createdAt).format("ll")}
              </p>
            </div>
            <div className="title-wrpr">
              <h1 className="text-dark blog-title">
                {blogData?.defaultContent?.[0]?.title}
              </h1>
              <div className="d-flex align-center blog-tag-wrpr">
                {blogData?.defaultContent?.[0]?.keywords?.length > 0 &&
                  blogData?.defaultContent?.[0]?.keywords?.map(
                    (_keyword, index) => (
                      <p key={index} className="text-dark blog-tag">
                        {_keyword}
                      </p>
                    )
                  )}
              </div>
            </div>

            <div className="blog-img-wrpr">
              <div className="blog-img-slider">
                <Slider {...BlogImagesSlickSetting}>
                  {blogData?.attachmentVideo?.length > 0 &&
                  blogData?.attachment?.length > 0
                    ? [
                        ...blogData?.attachmentVideo,
                        ...blogData?.attachment,
                      ]?.map((_data, index) => (
                        <div className="blog-slide" key={index}>
                          {index === 0 ? (
                            <>
                              <div className="video-container">
                                <video
                                  controls
                                  width={"100%"}
                                  height={"auto"}
                                  src={`${BASE_URL}${_data?.path}`}
                                  className="img-fluid"
                                />
                              </div>

                              {/* <a href="javascript:;" className="play-icon-wrpr">
                                <img
                                  src="/images/play-icon.svg"
                                  className="img-fluid play-icon"
                                />
                              </a> */}
                            </>
                          ) : (
                            <img
                              src={`${BASE_URL}${_data?.path}`}
                              className="img-fluid"
                            />
                          )}
                        </div>
                      ))
                    : blogData?.attachmentVideo?.length > 0
                    ? blogData?.attachmentVideo?.map((_data, index) => (
                        <div className="blog-slide" key={index}>
                          <div className="video-container">
                            <video
                              controls
                              width={"100%"}
                              height={"auto"}
                              src={`${BASE_URL}${_data?.path}`}
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      ))
                    : blogData?.attachment?.length > 0
                    ? blogData?.attachment?.map((_data, index) => (
                        <div className="blog-slide" key={index}>
                          <img
                            src={`${BASE_URL}${_data?.path}`}
                            className="img-fluid"
                          />
                        </div>
                      ))
                    : null}
                </Slider>
              </div>
            </div>
            <div
              className="blog-content-wrpr"
              dangerouslySetInnerHTML={{
                __html: blogData?.defaultContent?.[0]?.description,
              }}
            ></div>
          </div>
        )}
      </div>

    </>
  );
}

export default BlogDetail;

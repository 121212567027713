import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useRoutes } from "react-router-dom";
import { getAllPermissions, postRole } from "../../../actions/acl";
import InputField from "../../../components/FormFields/InputField";
import MultiSelectCheckboxField from "../../../components/FormFields/MultiSelectCheckboxField";

function AddRole() {
  // ------------------- Use hooks -----------------------
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const navigate = useNavigate();

  // ------------------- Global states ---------------------
  const [permissions, setPermissions] = useState([]);

  const onSubmit = async (data) => {
    try {
      await postRole(data);
      toast.success("Role added successfully");
      navigate("/dashboard/roles");
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const fetchPermissions = useCallback(async () => {
    try {
      const resp = await getAllPermissions();
      setPermissions(resp?.data?.responseData?.permissions);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchPermissions();
  }, [fetchPermissions]);

  return (
    <>
      <div className="container ani-ui-block shop-manager">
        <div className="row menu-top-block">
          <div className="col-sm-12 tl-block align-self-center">
            <h4 className="heading">Add role</h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="add-left-block">
                  <div className="fields-ui-block">
                    <div className="basic-details">
                      <div className="heading">
                        <h4>Basic Details</h4>
                      </div>
                      <div className="form-block">
                        <div className="row">
                          <div className="col-lg-4">
                            <InputField
                              control={control}
                              label="Role name"
                              name="name"
                              type="text"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "This field is required",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="fields-ui-block">
                    <div className="basic-details">
                      <div className="heading">
                        <h4>Permissions</h4>
                      </div>
                      <div className="form-block">
                        <div className="row">
                          <div className="col-lg-12">
                            <MultiSelectCheckboxField
                              control={control}
                              name="permissions"
                              label={"Message type"}
                              placeholder="Message Type"
                              options={permissions}
                              optionValue="id"
                              optionLabel="permissionCode"
                              normalize={(_value) => _value?.value}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Permission is required",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row save-button-block">
                    <div className="col-sm-12 align-self-center">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`btn orange-btn ${
                          isSubmitting ? "btn-loader" : ""
                        }`}
                      >
                        Submit details
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddRole;

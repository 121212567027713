import { Route, Routes } from "react-router-dom";
import AddSubCategory from "./SubCategory/AddSubCategory";
import EditSubCategory from "./SubCategory/EditSubCategory";

import SubCategory from "./SubCategory/SubCategory";
import Category from "./Category/Category";
import AddCategory from "./Category/AddCategory";
import EditCategory from "./Category/EditCategory";

function CategoryAndSubCategory() {
  return (
    <>
      <Routes>
        <Route index element={<Category />} />
        <Route path="add" element={<AddCategory />} />
        <Route path=":id/edit" element={<EditCategory />} />

        <Route path=":id/sub-category" element={<SubCategory />} />
        <Route path=":id/sub-category/add" element={<AddSubCategory />} />
        <Route
          path=":id/sub-category/:categoryId/edit"
          element={<EditSubCategory />}
        />
      </Routes>
    </>
  );
}

export default CategoryAndSubCategory;

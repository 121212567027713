import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

import ConfirmAlert from "../../components/Common/Modals/ConfirmAlert";
import { deleteTimeslot } from "../../actions/timeSlot";

function TableData({ srno, index, data, reload }) {
  //   local variables
  const [status, setStatus] = useState(data?.status);
  const [confirmShow, setConfirmShow] = useState(false);
  const [deleteBtnClicked, setDeleteBtnClicked] = useState(false);

  const deleteRecord = async () => {
    try {
      await deleteTimeslot({ id: data?.id });
      if (reload) reload();
      toast.success("Time Slot has been deleted successfully");
    } catch (error) {
      toast.error(`${error?.response?.data?.message}`);
    }
  };

  console.log(data?.time,'{data?.time}')

  //   use hooks
  useEffect(() => {
    setStatus(data?.status);
  }, [data.status]);

  return (
    <>
      <tr className="modal-tr">
        <td>{parseInt(srno + parseInt(index + 1))}</td>
        <td>{data?.time} minutes</td>
        <td>
          <div className="table-btn-block">
            <div className="table-btn-block">
              <Link
                className="btn edit-btn"
                to={`/dashboard/time-slot/${data?.id}/edit`}
              >
                Edit
              </Link>

              <button
                className="btn delete-btn"
                onClick={() => {
                  setDeleteBtnClicked(true);
                  setConfirmShow(true);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </td>
      </tr>

      {confirmShow ? (
        <ConfirmAlert
          show={confirmShow}
          onHide={() => {
            setConfirmShow(false);
          }}
          title={
            deleteBtnClicked
              ? "Are you sure you want to delete this Time Slot?"
              : status === 0
              ? "Are you sure to activate Time Slot?"
              : "Are you sure to deactivate Time Slot?"
          }
          confirmButtonName={"Yes"}
          cancelButtonName={"No"}
          onButtonClick={() => {deleteRecord()
          }}
        />
      ) : null}
    </>
  );
}

export default TableData;

import { useCallback, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Pagination from "react-js-pagination";

import { limit } from "../../../constants";
import CustomerFilter from "./CustomerFilter";
import TableData from "./TableData";
import { getUsersOrVets } from "../../../actions/Users";

function Customers() {
  // use hooks
  const { search } = useLocation();

  // local variables
  const [list, setList] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [filters, setFilters] = useState(queryString.parse(search));

  const datas = {
    data: [
      {
        id: 1,
        name: "Priya",
        email: "priya@illuminz.com",
        countryCode: "+91",
        phoneNumber: "9879874444",
        gender: 'Female',
        status: 1,
      },
    ],
    perPage: 10,
    totalRecords: 1
  };

  //   download data in csv format
  // const onCsvDownload = () => {
  //   try {
  //     //const resp = await deleteUser({ userId: data?.id });
  //     const csvContent =
  //       "data:text/csv;charset=utf-8," + JSON.stringify(datas?.data);
  //     const encodedUri = encodeURI(csvContent);
  //     const link = document.createElement("a");
  //     link.setAttribute("href", encodedUri);
  //     link.setAttribute("download", "Customer.csv");
  //     document.body.appendChild(link);
  //     link.click();
  //   } catch ({ response, request }) {
  //     if (response) {
  //       toast.error(response?.data?.message);
  //     } else if (request) {
  //       toast.error("You do not have internet connection");
  //     }
  //   }
  // };

  const fetchData = useCallback( async (page, filters) => {
    try {
      const resp = await getUsersOrVets({ ...filters, pageNumber: page, role: 'owner' });
      setList(resp?.data?.responseData);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  },[]);

  const handlePageChange = (page) => {
    setActivePage(page);
  };

  const getFilterFields = (filters) => {
    setActivePage(1);
    setFilters(filters);
  };

  useEffect(() => {
    fetchData(activePage, filters);
  }, [fetchData, activePage, filters]);

  return (
    <>
      <div className="right-ui-block">
        <div className="scrollspy-example">
          <div className="rm-content">
            <div className="row menu-top-block">
              <div className="col-sm-5 tl-block align-self-center">
                <h4 className="heading">
                  Pet Owners ({list?.totalRecords ? list.totalRecords : 0})
                </h4>
              </div>

              {/* <div className="col-sm-7 tr-block text-right align-self-center">
                <button
                  className="btn mr-2"
                  onClick={() => {
                    onCsvDownload();
                  }}
                >
                  DOWNLOAD CSV
                </button>

                <Link className="btn orange-btn" to="/dashboard/customers/add">
                  Add
                </Link>
              </div> */}
            </div>
            <CustomerFilter getFilterFields={getFilterFields} />
            <div className="row">
              <div className="col-sm-12">
                <div className="result-listing">
                  <div className="table-responsive">
                    <table>
                      <thead>
                        <tr>
                          <th>Sr.no.</th>
                          <th className="manage-content">Name</th>
                          <th className="manage-content">Email</th>
                          <th className="manage-content">Phone Number</th>
                          <th className="manage-content">Country</th>
                          <th className="manage-content">Pets Info</th>
                          {/* <th className="manage-content">Address</th> */}
                          <th className="manage-content">Gender</th>
                          <th className="manage-content">Status</th>
                          <th className="manage-content">Send Email</th>
                          {/* <th className="manage-content text-center">
                            Actions
                          </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {list === null ? (
                          <tr>
                            <td colSpan={10} align="center">
                              <div className="table-loader" />
                            </td>
                          </tr>
                        ) : list?.users?.length > 0 ? (
                          list?.users?.map?.((obj, index) => (
                            <TableData
                              key={obj.id}
                              data={obj}
                              index={index}
                              srno={(activePage - 1) * limit}
                              reload={() => fetchData(activePage, filters)}
                            />
                          ))
                        ) : (
                          <tr>
                            <td
                              className="no-record"
                              colSpan={10}
                              align="center"
                            >
                              No record found.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {list?.users?.length > 0 && (
                    <div className="pagination">
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={
                            list?.perPage ? list?.perPage : limit
                        }
                        totalItemsCount={
                            list?.totalRecords ? list?.totalRecords : 0
                        }
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Customers;

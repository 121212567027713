import { useEffect, useState } from "react";
import { toast } from 'react-hot-toast';
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import { verifyResetToken } from "../../actions/auth";
import ResetPasswordForm from "./ResetPasswordForm";

function ResetPassword() {

  // local variables
  const [isTokenVerified, setIsTokenVerified] = useState(false);
  const [token, setToken] = useState(null);

  // use hooks
  const { search } = useLocation();

  const checkResetToken = async(token) => {
    try{
      await verifyResetToken({token});
      setIsTokenVerified(true);
    }catch({response}){
      setIsTokenVerified(false);
      // toast.error(`${response?.data?.message}`);
    }
  }

  useEffect(()=> {
    if(token){
      checkResetToken(token)
    }
  },[token]);

  useEffect(() => {
    const value = queryString.parse(search);
    setToken(value?.token);
  }, [setToken, search]);

  return (
    <>
      <div style={{ height: "100%" }}>
        <div className="login-page">
          <div className="login-logo">
          <img src="/assets/images/logo.png" alt="Kinn" />
          </div>
          <div className="login-head">
            <h2>
              <span>Admin Portal</span>
            </h2>
            {
               isTokenVerified ? <p>Please fill the details to continue</p> : null
            }
          </div>
          {
            isTokenVerified
            ?
            <ResetPasswordForm />
            :
            <h3>Token expired</h3>
          }
        </div>
      </div>
    </>
  );
}

export default ResetPassword;

import { Modal } from "react-bootstrap";

function ConfirmAlert({
  show,
  onHide,
  title,
  confirmButtonName,
  cancelButtonName,
  onButtonClick
}) {

  // handling button click on their selected type
  const onConfirmation = (type) => {
    if (type === "Yes") {
        onButtonClick();
    }
    onHide();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        className="modal fade"
        dialogClassName="modal-dialog modal-md"
      >
        <div className="modal-header">
          <div className="title-btn-wrpr">
            <h2 className="modal-title mr20">Confirmation</h2>
          </div>
          <div className="table-btns modal-btns">
            <button type="button" className="close" onClick={onHide}></button>
          </div>
        </div>

        <div className="modal-body">
          <p>{title}</p>
          <div className="row">
            <div className="col-6"></div>
            <div className="col-6 text-right">
              <button type="button" className="mr-2" onClick={() => { onConfirmation("No") }}>
                {cancelButtonName}
              </button>
              <button type="button" className="" onClick={() => { onConfirmation("Yes") }}>
                {confirmButtonName}
              </button>
            </div>
            {/* <div className="col-3">
             
            </div> */}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ConfirmAlert;

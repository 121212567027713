import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import ToolTip from "../../components/Common/ToolTip";
import ConfirmAlert from "../../components/Common/Modals/ConfirmAlert";
import { planDelete, planStatus } from "../../actions/plan";

function TableData({ srno, index, data, reload }) {

  // usehooks
  const { id } = useParams();

    //   local variables
  const [status, setStatus] = useState(data?.status);
  const [confirmShow, setConfirmShow] = useState(false);
  const [deleteBtnClicked, setDeleteBtnClicked] = useState(false);

  const updateUserStatus = async (data) => {
    try {
      await planStatus({ id: data?.id, status: status == 1 ? 0 : 1 });
      setStatus(status === 1 ? 0 : 1);
      toast.success("Status Updated Successfully");
    } catch (error) {
      toast.error(`${error?.response?.data?.message}`);
    }
  };

  const deleteRecord = async () => {
    try {
      await planDelete({ id: data?.id });
      if(reload) reload();
      toast.success("Plan has been deleted successfully");
    } catch (error) {
      toast.error(`${error?.response?.data?.message}`);
    }
  };

  //   use hooks
  useEffect(() => {
    setStatus(data?.status);
  }, [data.status]);

  return (
    <>
      <tr className="modal-tr">
        <td>{parseInt(srno + parseInt(index + 1))}</td>
        
        <td>{data?.mainContent?.name}</td>
        <td>
          <div
              className="blog-content-wrpr"
              dangerouslySetInnerHTML={{
                __html: data?.mainContent?.description,
              }}
            ></div>
        </td>
        <td>{data?.PlanPrices?.Currency?.symbol}{data?.PlanPrices?.amount}</td>
         <td>{data?.cureentActiveSubscribers ?? 0}</td>
        <td>{data?.totalSubscribers ?? 0}</td>
        <td>
            <div
              className="status-ui"
              onClick={() => {
                setDeleteBtnClicked(false);
                setConfirmShow(true);
              }}
            >
              {status === 1 ? (
                <ToolTip title="Click to deactivate">
                  <div className="stock-field on-btn">
                    <label className="switch">
                      <div className="switch-slider"></div>
                    </label>
                  </div>
                </ToolTip>
              ) : (
                <ToolTip title="Click to activate">
                  <div className="stock-field off-btn">
                    <label className="switch">
                      <div className="switch-slider"></div>
                    </label>
                  </div>
                </ToolTip>
              )}
            </div>
        </td>
       
        <td>
          <div className="table-btn-block">
            <div className="table-btn-block">
              <Link
                className="btn edit-btn"
                to={`/dashboard/plans/${data?.id}/edit`}>
                Edit
              </Link>

              <button
                className="btn delete-btn"
                onClick={() => {
                  setDeleteBtnClicked(true);
                  setConfirmShow(true);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </td>
      </tr>
      {confirmShow ? (
        <ConfirmAlert
          show={confirmShow}
          onHide={() => {
            setConfirmShow(false);
          }}
          title={
            deleteBtnClicked
              ? "Are you sure you want to delete this plan?"
              : status === 0
              ? "Are you sure to activate plan?"
              : "Are you sure to deactivate plan?"
          }
          confirmButtonName={"Yes"}
          cancelButtonName={"No"}
          onButtonClick={() => {
            deleteBtnClicked ? deleteRecord(id) : updateUserStatus(data);
          }}
        />
      ) : null}
    </>
  );
}

export default TableData;

import { Link } from "react-router-dom";
import toast from 'react-hot-toast';
import { deleteRole } from "../../../actions/acl";
import ConfirmAlert from "../../../components/Common/Modals/ConfirmAlert";
import { useState } from "react";

function TableData({ srno, index, data, reload }) {

  // local variables
  const [confirmShow, setConfirmShow] = useState(false);

  const deleteRecord = async () => {
    const payload ={roleId:data?.id}
    try {
      await deleteRole(payload);
      reload()
      toast.success("Role has been deleted successfully");
    } catch (error) {
      toast.error(`${error?.response?.data?.message}`);
    }
  };

  return (
    <>
      <tr className="modal-tr">
        <td>{parseInt(srno + parseInt(index + 1))}</td>
        <td>
          <a
            className="table-modal only-link"
            href="javascript:;"
          >
            {data?.name}
          </a>
        </td>
        <td>
          <div className="table-btn-block">
            <div className="table-btn-block">
              <Link
                className="btn edit-btn"
                  to={`/dashboard/roles/${data?.id}/edit`}
              >
                Edit
              </Link>

              <button
                className="btn delete-btn"
                onClick={()=> setConfirmShow(true)}
              >
                Delete
              </button>
            </div>
          </div>
        </td>
      </tr>

      {confirmShow ? (
        <ConfirmAlert
          show={confirmShow}
          onHide={() => {
            setConfirmShow(false);
          }}
          title={
              "Are you sure you want to delete this Role?"
          }
          confirmButtonName={"Yes"}
          cancelButtonName={"No"}
          onButtonClick={() => {
            deleteRecord()
          }}
        />
      ) : null}
    </>
  );
}

export default TableData;
